import React, { useState }  from 'react';
import halfordsLogo from "../assets/images/halfordsLogoNew.png";
import cycleToWorkLogo from "../assets/images/cycleToWorkLogo.jpeg";
import locations from "../assets/images/LocationsNew.png";
import account from "../assets/images/AccountNew.png";
import {headerLinks} from './ScreenLabels'
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = () => {
  const [menuClickBool, setMenuClickBool] = useState(false);
    const toggleNavBarMenu = ()=>{
      setMenuClickBool(!menuClickBool);
    }
  return (
    <>
    {/* //sticky-top */}
      <nav className="navbar navbar-expand-sm ">
        <div className="container-fluid">
          <a className="navbar-brand" href={headerLinks.halfordsIcon} target="_blank" rel="noreferrer">
            <img className="header-logo" src={halfordsLogo} alt="halfords" />         
          </a>
          <a className="navbar-brand" href={headerLinks.c2wIcon} target="_blank" rel="noreferrer">          
            <img className="header-logo" src={cycleToWorkLogo} alt="cycle2work" />
          </a>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
            onClick={()=>{toggleNavBarMenu()}}
          >
            <span className="white-text">
            <FontAwesomeIcon icon={faBars} />
            </span>
          </button>
           
          <div  
          className={ menuClickBool ? "collapse navbar-collapse show" : "collapse navbar-collapse"}
          id="mynavbar">
          <ul className="navbar-nav mr-auto">
              <li className="nav-item"></li>
              <li className="nav-item"></li>
            </ul>
            <form className="d-flex">
              <div className="navbar-nav ml-auto mt-1 d-flex">
                <div className="nav-item locations-order-element">
                  <a
                    href={headerLinks.locationsIcon}
                    className="nav-link d-flex flex-column"
                    data-toggle="collapse"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className='header-menu-image'>
                      <img
                        className="header-location-image"
                        src={locations}
                        alt="locations"
                      />
                    </span>
                    <span className="d-none d-sm-inline header-menu-text">
                      Locations
                    </span>
                  </a>
                </div>
                <div className="nav-item account-order-element">
                  <a
                    href={headerLinks.accountIcon}
                    className="nav-link d-flex flex-column"
                    data-toggle="collapse"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className='header-menu-image'>
                      <img
                        className="header-account-image"
                        src={account}
                        alt="account"
                      />
                    </span>
                    <span className="d-none d-sm-inline header-menu-text">
                      Account
                    </span>
                  </a>
                </div>
              </div>
            </form>
            
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header