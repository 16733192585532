import React, { useContext, useEffect, useState } from "react";
import { Context } from "./Context";
import "../App.css";
import {
  hireAgreementEnvironmentURL,
  applicationUpdateAPIurl,environment
} from "./ApiUrls";
import axios from "axios";

const HireAgreementDocusign = () => {
  const [context, setContext] = useContext(Context);
  const [agreed, setAgreed] = useState(false);
  const [loaderBool, setLoaderBool] = useState(false)
  useEffect(() => {
    const docusignIframe = document.querySelector('[data-qa="ClickwrapIframeApp"]');
    const handleIframeLoad = () => setLoaderBool(true);   
    docusignIframe.addEventListener('load', handleIframeLoad);
    return () => {
      // Clean up the event listener on component unmount
      docusignIframe.removeEventListener('load', handleIframeLoad);
    };
  }, []);
  useEffect(() => {
    if (agreed && context.step === 8) {
      submitapplicationAPI();
      sendMailFromDocusign();
    }
  }, [agreed]);
  
  const submitapplicationAPI = () => {
    setAgreed(false);
    const postData = {
      ...context.marketingDetails,
      ipaddress: context.ipAddr,
      BespokeAnswer1Other: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeAnswer1Other
        : "",
      BespokeAnswer2Other: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeAnswer2Other
        : "",
      BespokeAnswer3Other: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeAnswer3Other
        : "",
      BespokeAnswer4Other: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeAnswer4Other
        : "",
      BespokeAnswer5Other: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeAnswer5Other
        : "",
      BespokeSelectedAnswer1: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeSelectedAnswer1
        : "",
      BespokeSelectedAnswer2: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeSelectedAnswer2
        : "",
      BespokeSelectedAnswer3: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeSelectedAnswer3
        : "",
      BespokeSelectedAnswer4: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeSelectedAnswer4
        : "",
      BespokeSelectedAnswer5: context.addQuesSelectedData
        ? context.addQuesSelectedData.BespokeSelectedAnswer5
        : "",

      AdditionalQAExisted: context.stepThreePostData.AdditionalQAExisted,
      "Alredy Existed": context.stepThreePostData["Alredy Existed"],
      BespokeQuesiton1: context.stepThreePostData.BespokeQuesiton1,
      BespokeQuesiton2: context.stepThreePostData.BespokeQuesiton2,
      BespokeQuesiton3: context.stepThreePostData.BespokeQuesiton3,
      BespokeQuesiton4: context.stepThreePostData.BespokeQuesiton4,
      BespokeQuesiton5: context.stepThreePostData.BespokeQuesiton5,
      ContactID: context.stepThreePostData.ContactID,
      Emailcode: context.stepThreePostData.Emailcode,
      SchemeMax: context.stepThreePostData.SchemeMax,
      SchemeMin: context.stepThreePostData.SchemeMin,
      SchemeValid: context.stepThreePostData.SchemeValid,
      address: context.stepThreePostData.address,
      addressLine1: context.stepThreePostData.addressLine1,
      addressLine2: context.stepThreePostData.addressLine2,
      addressLine3: context.stepThreePostData.addressLine3,
      addressTown: context.stepThreePostData.addressTown,
      addressPostCode: context.stepThreePostData.addressPostCode,
      dob: context.stepThreePostData.dob,
      email: context.stepThreePostData.email,
      mobile: context.stepThreePostData.mobile,
      name: context.stepThreePostData.name,
      employeeFirstName: context.stepThreePostData.employeeFirstName,
      employeeLastName: context.stepThreePostData.employeeLastName,
      agreementNumber: context.agreementNumber,
      payroll: context.stepThreePostData.payroll,
      schemecode: context.stepThreePostData.schemecode,
      schemename: context.stepThreePostData.schemename,
      Frequency: context.stepFourPostData.Frequency,
      LoCVal: context.stepFourPostData.LoCVal,
      requiresGuarantor: context.stepThreePostData.requiresGuarantor,
      hireAgreement: true,
    };
    submitPostAPICall(postData);
  };
  const getToken = async ()=>{ 
    let tokenObj={}      
    await axios.get('/post')
    .then(res => {tokenObj=res.data})
    .catch(e=>{console.log(e)});   
    return tokenObj;     
  }
  const submitPostAPICall = async(postData) => {
    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey            
    }
    axios({
      url: applicationUpdateAPIurl,
      method: "POST",
      data: postData,
      headers:headers
    })
      .then((resp) => {
        setTimeout(() => {
          setContext({ ...context, step: 10, hireAgreement: true });
        }, 200);
      })
      .catch((err) => {
        console.log(err);
      });
  }; 

  let environment = hireAgreementEnvironmentURL;
  let accountId = "dd836b7d-962e-4e35-b573-0d36865ca3a1";
  let clickwrapId = "6382f58f-1a36-45bc-bbce-31038b06ca3f";
  let clientUserId = new Date().getTime();
  let name = "Employee Name";
  let address = "Employee Address";
  let email = "Employee.Email@example.com";
  let dateTime = new Date(Date.now()).toLocaleString("en-GB").split(",");
  let timeAndDate =
    dateTime[1].trim().replaceAll("\\s", "") +
    " " +
    dateTime[0].trim().replaceAll("\\s", "");
  let date = dateTime[0].trim();
  let companyName = "company name";
  let agreementNumber = "agreement number";
  let mainContact = "main contact";
  let limit = 100;
  let term = "12 month";
  let currencySymbol = context.currencySymbol;
  let currentStep = context.step;
  if (context.stepThreePostData) {
    name = context.stepThreePostData.name;
    address = context.stepThreePostData.address;
    email = context.stepThreePostData.email;
    agreementNumber = context.agreementNumber;
  }
  if (context.stepFourPostData) {
    limit = context.stepFourPostData.LoCVal;
    term = context.stepOneResponse.salsacperiod;
    term= term !==""?term+" Month":"12 Month";
  }
  if (context.hireAgreementDetails) {
    clientUserId = email + "_" + context.hireAgreementDetails.agreementTime;
    companyName = context.hireAgreementDetails.companyName;
    mainContact = context.hireAgreementDetails.mainContact;
  }
  let documentData = {
    email: email,
    fullName: name,
    company: companyName,
    date: date,
    address: address,
    dateAndTime: timeAndDate,
    agreementNumber: agreementNumber,
    mainContact: mainContact,
    limit: limit,
    term: term,
    title: term,
    clientUserId: clientUserId,
  };
  const agreeButtonStyles = {
    fontSize: "18px",
    color: "white",
    fontWeight: "bold",
    fontFamily: "Helvetica, Arial, sans-serif",
    height: "40px",
    backgroundColor: "#BBC74D",
    borderRadius: "7px",
    border: "1px solid transparent",
    padding: ".375rem .75rem",
    ":hover": {
      backgroundColor: "#3860B2",
    },
  };

  if (context.step === 6) {
    accountId = context.hireAgreementDetails.preHireAgreementAccountid;
    clickwrapId = context.hireAgreementDetails.preHireAgreementClickwrapid;
    documentData = null;
  } else if (context.step === 7) {
    accountId = context.hireAgreementDetails.hireAgreementAccountid;
    clickwrapId = context.hireAgreementDetails.hireAgreementClickwrapid;
    documentData = { fullName: name };
  } else if (context.step === 8) {
    accountId = context.hireAgreementDetails.salSacAgreementAccountid;
    clickwrapId = context.hireAgreementDetails.salSacAgreementClickwrapid;
    documentData = {
      email: email,
      fullName: name,
      company: companyName,
      date: date,
      address: address,
      dateAndTime: timeAndDate,
      agreementNumber: agreementNumber,
      mainContact: mainContact,
      limits: limit, 
      title: term,
      currencySymbol: currencySymbol,
    };
  }

  React.useLayoutEffect(() => {
    window.docuSignClick.Clickwrap.render(
      {
        environment: environment,
        accountId: accountId,
        clickwrapId: clickwrapId,
        clientUserId: clientUserId,

        // Called if any errors occur during agreement or configuration
        onError(error) {
          console.log(error.errorMessage);
        },
        onAgreed(agreement) {
          // Called when either 1) the clientUserId has previously agreed
          // 2) the clientUserId has clicked agree and completed successfully
          // set delay for response to save in portal
          if (context.step === 8) {
            setAgreed(true);
          }
          setTimeout(() => {
            if (context.step === 6) {
              setContext({ ...context, step: 7, isPHAagreed: true });
            } else if (context.step === 7) {
              setContext({ ...context, step: 8 });
            } 
          }, 400);
        },
        onAgreeing(agreementData) {
        },
        onDeclined(agreement) {
          // Called when the clientUserId has declined successfully
          setContext({
            ...context,
            step: 4,
            isPHAagreed: false,
            changeAmount: true,
          });
        },
        documentData: documentData,
        style: {
          header: {
            display: "none",
          },
          agreeButton: agreeButtonStyles,
        },
      },
      "#ds-clickwrap"
    );
  }, []);

  const sendMailFromDocusign = () => {
    let documentData = {
      email: email,
      fullName: name,
      company: companyName,
      date: date,
      address: address,
      dateAndTime: timeAndDate,
      agreementNumber: agreementNumber,
      mainContact: mainContact,
      limit: limit,
      term: term,
      title: term,
      clientUserId: clientUserId,
      currencySymbol: currencySymbol,
    };
    axios
      .post("/post", documentData)
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="ds-clickwrap">  
    {!loaderBool && <>
      <div className="loader aggreementLoader"> </div>
      <div className="loading-text aggreementLoaderText">Loading document..</div>
    </>}     
      
      <div id="ds-clickwrap">    
              Docusign data loading ....
      </div>
    </div>
  );
};

export default HireAgreementDocusign;
