import React from "react";
import instagram from "../assets/images/Instagram.png";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/Twitter.png";
import youtube from "../assets/images/Youtube.png";
import blog from "../assets/images/blog.png";
import {footerLinks,footerContent} from "./ScreenLabels"

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer-row">
          <div className="d-block footer-left-column">
            <div className="footer-follow-icons">
              <div className="footer-main-content">Follow us:</div>
              <a href={footerLinks.instagramIcon} target="_blank">
                <img className="footer-left-icons" src={instagram} alt="instagram" />
              </a>
              <a href={footerLinks.facebookIcon} target="_blank">
                <img className="footer-left-icons" src={facebook} alt="facebook" />
              </a>              
              <a href={footerLinks.twitterIcon} target="_blank">
                <img className="footer-left-icons" src={twitter} alt="twitter" />
              </a>              
              <a href={footerLinks.youtubeIcon} target="_blank">
                <img className="footer-left-icons" src={youtube} alt="youtube" />
              </a>              
              <a href={footerLinks.blogIcon} target="_blank">
                <img className="footer-left-icons" src={blog} alt="blog" />
              </a>              
            </div>
          </div>
          
          <div className="d-block footer-right-column">
            <div className="footer-main-content">
              {footerContent.helpAndAdvice}
            </div>
            <div className="footer-sub-content">
              <a href={footerLinks.fAQs} target="_blank" >
                {footerContent.fAQs}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.findYourLocalHalfords} target="_blank">
                {footerContent.findYourLocalHalfords}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.trackCancelYourOrder} target="_blank">
                {footerContent.trackCancelYourOrder}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.trackMyBooking} target="_blank">
                {footerContent.trackMyBooking}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.returns} target="_blank">
                {footerContent.returns}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.homeDelivery} target="_blank">
                {footerContent.homeDelivery}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.clickAndCollect} target="_blank">
                {footerContent.clickAndCollect}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.tradeCard} target="_blank">
                {footerContent.tradeCard}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.paymentMethods} target="_blank">
                {footerContent.paymentMethods}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.eGifts} target="_blank">
                {footerContent.eGifts}
              </a>
              </div>
            <div className="footer-sub-content">
            <a href={footerLinks.contactUs} target="_blank">
                {footerContent.contactUs}
              </a>
              </div>
          </div>          
        </div>
      </footer>
    </>
  );
};

export default Footer;
