import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stepSevenLabels } from "../components/ScreenLabels";
import HireAgreementDocusign from "../components/HireAgreementDocusign";
import ProgressBar from "../components/ProgressBar";
import { useEffect } from "react";
const StepSeven = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });      
  },[]);
  return (
    <div className="form-element">
      <div className="start-text text-center">
        <ProgressBar progress={stepSevenLabels.progressBarCount} />
        <span>{stepSevenLabels.progressBarText} </span>
      </div>
      <div className="intro-label-new"> {stepSevenLabels.introLabel} </div>
      <div className="text-left">
        <label className="intro-sub-label">
          {stepSevenLabels.introSubLabel}
          &nbsp;&nbsp;
          <span className="tooltip-icon">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span className="tooltiptext">
              {stepSevenLabels.introSubLabelHint}
            </span>
          </span>
        </label>
      </div>
      <form className="text-left">
        <div>
          <HireAgreementDocusign />
        </div>
      </form>
    </div>
  );
};

export default StepSeven;
