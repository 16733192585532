import React, { useContext, useEffect, useState } from "react";
import { Context } from "../components/Context";
import { welcomeScreenLabels } from "../components/ScreenLabels";
import axios from "axios";
import { faCircleExclamation,faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import circleTickGreen from "../assets/images/circle-tick-green.svg";
import { totalTimeAPIUrl,} from "../components/ApiUrls";
import NetworkError from "../components/NetworkError";
const WelcomeScreen = () => {
  const [context, setContext] = useContext(Context);
  const [min, setMin] = useState(context.minutes?context.minutes:5);
  const [minBool, setMinBool] = useState(false);
  const [networkErrBool,setNetworkErrBool] = useState(false);
  const [apimHeaders,setApimHeaders]=useState({});
 
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    }); 
    getTimeTakenFromTable();
    clearCacheData();
    },[])

    const clearCacheData = () => {
      caches.keys().then((names) => {
          names.forEach((name) => {
              caches.delete(name);
          });
      });
    }
  

  const getToken = async ()=>{ 
    let tokenObj={}      
    await axios.get('/post')
    .then(res => {
        tokenObj=res.data        
    })
    .catch(e=>{console.log(e)});   
    return tokenObj;     
  }
  const getTimeTakenFromTable = async() => {   
    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey             
    }
    axios({
      url: totalTimeAPIUrl,
      method: "POST",
      headers:headers
    })
      .then((resp) => {
        if(networkErrBool) setNetworkErrBool(false);
        let response = resp.data[0];        
        setMin(response.ssp_minutes);
        setMinBool(true);
      })
      .catch((err) => {
        console.log(err);        
        err.code === "ERR_NETWORK"?setNetworkErrBool(true):setNetworkErrBool(false);
      });
  };
 

 
  const handleClick = (e) => {
    e.preventDefault();    
    setContext({
      ...context,
      step: 1,
      headers:apimHeaders
    });     
  };

  return (
    <div className="form-element">
      {networkErrBool && <NetworkError setNetworkPopup={setNetworkErrBool}/>}
      <div className="text-left">
        <label className="intro-label-new mt-5 mb-2">
          {" "}
          {welcomeScreenLabels.introTextLine1}{" "}
        </label>
        <br />
        <label className="intro-sub-label">
          {welcomeScreenLabels.introTextLine2}
        </label>
        <br />
      </div>
      <div className="text-left">
        <label className="intro-sub-label">
          {welcomeScreenLabels.headingLabel}
        </label>
      </div>
      <div className="required-list text-left ">
        <div className="d-flex">
          <img src={circleTickGreen} alt='circleTickGreen' className='mr-2' />
          <label className="mb-auto mt-auto">
            {welcomeScreenLabels.textLine1}&nbsp;&nbsp;
            <span className="tooltip-icon">
              <FontAwesomeIcon icon={faCircleExclamation} />
              <span className="tooltiptext">
                {welcomeScreenLabels.textLine1Hint}
              </span>
            </span>
          </label>
        </div>
        <div className="d-flex">
          <img src={circleTickGreen} alt='circleTickGreen' className='mr-2' />
          <label className="mb-auto mt-auto">
            {welcomeScreenLabels.textLine2}&nbsp;&nbsp;
            <span className="tooltip-icon">
              <FontAwesomeIcon icon={faCircleExclamation} />
              <span className="tooltiptext">
                {welcomeScreenLabels.textLine2Hint}
              </span>
            </span>
          </label>
        </div>
        <div className="d-flex">
          <div className="mt-auto mb-auto mr-2">
            <img src={circleTickGreen} alt='circleTickGreen' />
          </div>
          <div>
            <label className="mb-auto mt-auto">
              {welcomeScreenLabels.textLine3Part1}
              <a
                href={welcomeScreenLabels.textLine3Part2Linkhref}
                rel="noreferrer"
                target="_blank"
              >
                {welcomeScreenLabels.textLine3Part2}
              </a>
              {welcomeScreenLabels.textLine3Part3}
            </label>
          </div>
        </div>
        <div className="d-flex">
          <img src={circleTickGreen} alt='circleTickGreen' className='mr-2' />
          <label className="mb-auto mt-auto">
            {welcomeScreenLabels.textLine4Part1}
            {min}
            {welcomeScreenLabels.textLine4Part2}{" "}
          </label>
          <br />
        </div>
      </div>
      <div className="form-group text-center">
         <button
          type="submit"
          className="btn green-btn next-enable-button-element"
          disabled={!minBool}
          onClick={(e) => handleClick(e)}
        >
          {welcomeScreenLabels.nextButtonText}
          {/* <i className="fa fa-arrow-right ml-2"></i> 
           <FontAwesomeIcon icon={faArrowRight} /> */}
        </button>
      </div>
      <div className="text-left">
        <label className="text-left">{welcomeScreenLabels.textLine5}</label>
        <br />
        <label className="text-left">
          <a href={welcomeScreenLabels.textLine6Linkhref} rel="noreferrer" target="_blank">
            {welcomeScreenLabels.textLine6}
          </a>
          
        </label>
        <br />
      </div>
    </div>
  );
};

export default WelcomeScreen;
