export const experianAddressSearchAPIURL = "https://api.experianaperture.io/address/search/v1";
export const experianAddressValidateAPIURL = "https://api.experianaperture.io/address/validate/v1";
export const experianAddressAuthToken = "7738cb7f-af41-447f-ac3b-692e2c16c6ea"
export  const experianDetailsFromTablePostData = {"postdataForExperianAPI": "string"};
export const totalTimeParams = {"hireagreement": "string"};
export const thankyouScreenGetIpAPIURL="https://geolocation-db.com/json/";
export const thankyouScreenOnLoadParams ={ "marketing": "string" }


/** change 'environment' and 'hireAgreementEnvironmentURL' while changing environment */
// const environment = "DEV";
// const environment = "QA";
const environment = "PROD";

//dev and QA
//export const hireAgreementEnvironmentURL = "https://demo.docusign.net";

//PROD
 export const hireAgreementEnvironmentURL = "https://eu.docusign.net";




let baseURL = '';
if(environment==="DEV"){
    baseURL="https://dev.api.halfords.co.uk/SalarysacrificeLogicApp/";
}else if(environment==="QA"){
    baseURL="https://qa.api.halfords.co.uk/SalarysacrificeLogicApp/";
}else if(environment==="PROD"){
    baseURL="https://api.halfords.co.uk/SalarysacrificeLogicApp/";
}

// APIM endpoints
export const applicationCreateAPIurl =baseURL + "DV_applicationCreate_orch_pr";
export const applicationUpdateAPIurl=baseURL + "DV_applicationUpdate_orch_pr";
export const stepOneAPIURL =baseURL + "DV_empEmailVarificationLA_orch_pr";
export const stepTwoAPIUrl =baseURL + "DV_empEmailVarificationOTP_orch_pr";
export const errorMessageAPIURL = baseURL + "DV_empApp_errorMsg_PR";
export const experianDetailsFromTableAPIURL=baseURL + "DV_experianAuth_orch_pr";
export const stepFourAPIURL =baseURL + "DV_appSalaryBreakDown_orch_pr";
export const totalTimeAPIUrl =baseURL + "DV_hireAgreementIDs_orch_pr";
export const thankyouScreenOnLoadAPIURL= baseURL + "DV_thankyou_orch_pr";
export const hireAgreementAPIUrl=baseURL + "DV_hireAgreementIDsDocuSign_orch_pr";
export const thankyouScreenSubmitAPIurl =baseURL + "DV_appMarketing_orch_pr";


