export const headerLinks = {
    halfordsIcon : "https://www.halfords.com/",
    c2wIcon : "https://www.halfords.com/cycling/expert-advice/cycle2work.html",
    locationsIcon : "https://www.halfords.com/locations",
    accountIcon : "https://www.halfords.com/login?rurl=1",
}

export const footerLinks = {
    blogIcon : "https://blog.halfords.com/",
    facebookIcon : "https://www.facebook.com/HalfordsUK",
    instagramIcon : "https://www.instagram.com/Halfords_uk/",
    twitterIcon : "https://twitter.com/Halfords_UK",
    youtubeIcon : "https://www.youtube.com/halfords",
    fAQs: "https://www.halfords.com/customer-services/faqs",
    findYourLocalHalfords: "https://www.halfords.com/locations",
    trackCancelYourOrder:  "https://www.halfords.com/track-my-order",
    trackMyBooking:  "https://www.halfords.com/track-my-order",
    returns:  "https://www.halfords.com/help-and-advice/orders-and-bookings/returns-and-refunds/returns-information",
    homeDelivery: "https://www.halfords.com/help-and-advice/orders-and-bookings/your-order-and-bookings/delivery-options/delivery.html",
    clickAndCollect: "https://www.halfords.com/help-and-advice/orders-and-bookings/your-order-and-bookings/click-and-collect",
    tradeCard:  "https://www.halfords.com/help-and-advice/orders-and-bookings/payment-and-savings/trade-card",
    paymentMethods: "https://www.halfords.com/help-and-advice/orders-and-bookings/payment-and-savings/ways-to-pay/payment-methods.html",
    eGifts: "https://www.halfords.com/help-and-advice/orders-and-bookings/payment-and-savings/gift-card-information/gift-cards.html",
    contactUs: "https://www.halfords.com/customer-services/contact-us",    
}

export const footerContent = {
    helpAndAdvice : "Help and Advice",
    fAQs:  "FAQ's",
    findYourLocalHalfords: "Find your local Halfords",
    trackCancelYourOrder:  "Track/cancel your order",
    trackMyBooking:  "Track my booking",
    returns:  "Returns",
    homeDelivery: "Home Delivery",
    clickAndCollect: "Click & Collect",
    tradeCard:  "Trade Card",
    paymentMethods: "Payment Methods",
    eGifts: "eGifts",
    contactUs: "Contact us",
}
export const networkErrorMsg = "Sorry, we are unable to connect to the network. Please check your internet connection.";
export const schemecodeErrorMsg ={
    message:"Please check that you have entered your employer code correctly. If your employer code has been entered correctly and you are still seeing this message, please try entering your employer code ",
    link:"https://www.cycle2work.info/sign-up"
}
export const SAPidMissingLabels={
    SAPidMissingText: "Sorry. We are unable to proceed with your application as the account setup is still incomplete. Please contact cycle2.work@halfords.co.uk for further assistance.",
    okButtonText: "Close"
}
export const networkErroLabels={
    networkMsg: "Sorry, we are unable to connect to the network. Kindly, check your internet connection.",
    okButtonText: "Ok"
}
export const errorMessagesLocal = {"ibdCompanyNo":"Please enter a valid UK Company Registration Number","dobFormat":"Please Enter a valid YEAR","limitPart1":"The amount you've entered does not fall within the thresholds. Please enter a value greater than ","payroll":"Please check payroll number","ibdcharLimit":"Registration number must be 8 at least 8 digits in length","address":"Please enter valid address","schemecode":"Whoops! Please check that you have entered the employer code correctly.\n If the employer code has been entered correctly and you still see this message, please enter your employer code on https://www.cycle2work.info/sign-up.","dob":"Please enter a proper format","limitMax":"Your maximum amount is greater than the scheme maximum, please enter a value lower than","limitPart2":"and lower than ","mobile":"Please enter a valid number","dobReEnter":"Please re-enter your date of birth","frequencySelect":"Please select salary payment frequency","name":"Please make sure you enter a valid name","beforeStartDate":"Scheme code is not active yet","afterEndDate":"Scheme window has expired","email":"Please make sure you enter a valid email address","telephone":"Please make sure you enter a valid 11 digit telephone number","emailCode":"Invalid code, please try again","limitMin":"Your minimum amount is less than the scheme minimum, please enter a value greater than","noMatchEmail":"Email addresses do not match"};
export const welcomeScreenLabels ={
    introTextLine1:'Ready to get started?',
    introTextLine2: "Let's begin your Cycle2Work application now",
    headingLabel : 'Before you start, here are a few things to consider: ',
    img:'image.png',
    textLine1 : 'You will need your employer code',
    textLine1Hint : 'If your employer has a live scheme, they will have been given a unique employer code for you to enter when you apply. You can find this on any Cycle2Work emails or marketing around your workplace. If you can’t find your employer code, please contact your employer.',
    textLine2 : 'You will need your payroll number',
    textLine2Hint : 'You can find your payroll number on your payslip.',
    textLine3Part1 : 'You will need an idea of the value of the bike and accessories you would like for your commute (see our buyers guide',
    textLine3Part2 : ' here',
    textLine3Part3 : ')',
    textLine3Part2Linkhref:'https://www.halfords.com/bikes/buying-guides/bikes-buyers-guide.html',
    textLine4Part1 : 'This application will take approx ',
    textLine4Part2 : ' minutes to complete',
    textLine5 : 'Are you an employer looking to set up a Cycle2Work scheme?',
    textLine6 : 'Click Here',//'Find out more',
    textLine6Linkhref:'https://www.halfordsforbusiness.com/Cycle2Work/',
    nextButtonText:'CONTINUE',  

}

export const stepOneLabels = {

    introLabel:'1. Your Details',
    introSubLabel:'First we need a few details about you: ',
    introSubLabelHint:'We need to know who you are and have your contact details so we can communicate with you. We also need your home address for your hire agreement.',
    nameLabel: 'Enter your full name',
    firstNameLabel: 'Enter your first name',
    surNameLabel: 'Enter your surname',
    emailLabel: 'Enter your email address',
    confirmEmailLabel: 'Confirm your email address',
    schemecodeLabel: "Enter your Employer code for Cycle2Work ",
    schemecodeHint: 'You can usually find your Cycle2Work Employer code on posters around your workplace, or on an email sent to you by your employer about Cycle2Work. You can check if your employer has signed up to Halfords Cycle2Work ',
    schemecodeHintLink:'here.', //'https://www.halfords.com/cycling/expert-advice/cycle2work.html'
    schemecodeHintLinkhref:'https://www.halfords.com/cycling/expert-advice/cycle2work.html',
    CIDLabel: "Enter CID",
    PIDLabel: "Enter PID",
    nextButtonText:'Next',
}
export const stepOneOtherInfo ={    
    firstPlaceholder:'First Name',
    surPlaceholder:'Surname',
    // namePlaceholder:'Full Name',
    emailPlaceholder:'Email address',
    confirmEmailPlaceholder:'Confirm email address',
    schemecodePlaceholder:'Ask your employer',
    nameMaxLength:'50',
    emailMaxLength:'60',
    schemecodeMaxLength:'60',
    loaderText:"Checking employer code ...",
    CIDPlaceholder: "Enter CID",
    PIDPlaceholder: "Enter PID",
    progressBarCount:0,
    progressBarText:'Step 1 of 8'
    
}

export const stepTwoLabels = {
    introLabel:'2. Confirm Your Email',
    introSubLabel:'We just need to confirm it’s you: ',
    introSubLabelHint:'To continue your Cycle2work application and ensure you receive your documents, we need to validate your email address. Please follow the steps below.',
    emailCodeLabelPart1: 'We have sent you a CODE by email to ',
    emailCodeLabelPart2: 'Confirm your email by entering the code below.',
    nextButtonText:'Next',
    backButtonText:'Back',
    resendCodeText:'Resend code'
}
export const stepTwoOtherInfo ={
    sampleCode:'807441',
    emailCodePlaceholder:'e.g 123456',
    emailcodeMaxLength:'6',
    emailCodeValidText:'Emailed code is valid for 10 mins',
    emailCodeResendText:'Not received your code?',
    afterResendCodeText:'Code has been sent',
    progressBarCount:1,
    progressBarText:'Step 2 of 8'
}

export const stepThreeLabels = {
    introLabel:'3. More about you',
    introSubLabel:'We just need a few more details: ',
    introSubLabelHint:'We now just need some additional personal information to process your application and ensure it’s you.',
    dobLabel: 'Enter your Date of Birth',
    dobPopupHeaderLabel:'Please confirm ',
    dobPopupLabel:'Please confirm you are under 18',
    addressNotFoundOption:'Choose your address or click here to enter manually',
    addressLabel: 'Enter your home address ',
    manualAddressSpanText:'Or enter manually',
    manualAddrIntroLabel:'Enter address manually',
    manualAddrLine1Label:'Address Line1',
    manualAddrLine2Label:'Address Line2',
    manualAddrLine3Label:'Address Line3',
    manualAddrTownLabel:'Town/City',
    manualAddrPostCodeLabel:'Post code',
    manualAddrCancelToolTip:'To choose your address, click here',
    mobileLabel: 'Enter your mobile number',
    payrollLabel: 'Enter your Payroll Number',
    payrollHint: 'You can usually find your Payroll Number on payslips or your employers intranet portal. If you do not have this to hand, please contact your employer.',
    nextButtonText:'Next',
    backButtonText:'Back',
}

export const stepThreeOtherInfo ={
    dobPlaceholder:'',
    dobMinValue:'1900-01-01',
    addressPlaceholder:'Start typing',
    mobilePlaceholder:'Mobile number',
    payrollPlaceholder:'Ask your employer',
    addressMaxLength:'80',
    mobileMinLength:'9',
    mobileMaxLength:'20',
    payrollMaxLength:'80',
    progressBarCount:2,
    progressBarText:'Step 3 of 8'
}

export const stepFourLabels= {
    introLabel:'4. Your Application',
    introSubLabel:'Please select how much you would like to apply for through Cycle2Work: ',
    introSubLabelHint:'Please tell us about the amount you wish to spend on a bike and/or accessories. The value must be within the spend limit your employer has set. Please note, this should be total value of the bike and/or accessories you require before the saving is applied.',
    subHeadingTextLabelPart1: 'Your Company has a minimum scheme limit',
    subHeadingTextLabelPart2: 'and maximum scheme limit',
    limitLabel: 'How much would you like to apply for?',
    limitHint:'To complete this field, add up the value of your desired bike and/or accessories, you’ll need to enter this amount. The amount must be within the scheme limits your employer has set.',
    frequencyLabel: 'How often do you get paid?',
    frequencyLabel1:'Please select the correct frequency in which you are paid. Selection of the incorrect frequecy could result in an invalid application. Please check with your employer if you are unsure.',
    frequencyHint:'To complete this field, just tell us how often you are paid.',
    frequencyDefaultOption:'Choose from below',
    frequencyOptions:["Weekly", "Fortnightly", "4 Weekly", "Monthly"],
    nextButtonText:'Next',
    backButtonText:'Back',
}

export const stepFourOtherInfo ={
    limitPlaceholder:'0.00',
    poundSymbol:'£',
    euroSymbol:'€',
    loaderText:'Calculating ...' ,
    progressBarCount:3,
    progressBarText:'Step 4 of 8',
    otherInfoHeading:"Anything you've forgotten?",
    otherInfoText:'It is always recommended that as well as your bike you consider a way to keep it safe - a general rule of thumb is to include a lock worth 10% of the value of the bike.'
}

export const stepFiveLabels= {
    introLabel:'5. What You Could Save',
    introSubLabel:'Your Cycle2Work savings and regular payments are estimated below! ',
    introSubLabelHint:'The amount you could save depends on your circumstances and the cost of the bike and/or accessories you choose. That is why the calculations below are an estimate.',
    subHeadingTextLabelPart1: 'If you apply for ',
    subHeadingTextLabelPart2: ', you are predicted to save:',
    twentyPercentTaxBracket:'Basic rate tax payer',//'20% tax bracket'
    fourtyPercentTaxBracket:'Higher rate tax bracket',//'40% tax bracket'
    fourtyFivePercentTaxBracket:'Additional rate tax bracket',//'45% tax bracket'    
    twentyPercentTaxHint:'Estimated savings for basic rate taxpayer based on a 32% saving (20% tax saving and 12% national insurance saving).',
    fourtyPercentTaxHint:'Estimated savings for higher rate taxpayer based on a 40% saving (40% tax saving and 2% national insurance saving).',
    fourtyFivePercentTaxHint:'Estimated savings for additional rate taxpayer based on a 45% saving (45% tax saving and 2% natoinal insurance saving).',
    youWillSave:'You could save ',
    paymentsOf:'payments of ',
    paymentsOfFor4Weelky:'payments (every 4 weeks) of',
    progressBarCount:4,
    progressBarText:'Step 5 of 8',
    nextButtonText:'Pre Contract Agreement',
    backButtonText:'Change Amount',
    
}

export const stepSixLabels={
    introLabel:'6. Pre-Contract Information',
    introSubLabel:'Please read the following carefully, when you are happy you can tick the box below to confirm you have read the important information: ',
    introSubLabelHint:"Before you can access the Cycle2Work Hire Agreement, we just need you to read through the important information below regarding your Hire Agreement. We will need you to confirm you have read and understood the information by ticking the box below.",
    subHeadingTextLabel: 'Please read the following carefully, when you are happy, you can digitally sign the hire agreement below.',
    checkboxLabel:'Click here to accept the contract agreement',
    progressBarCount:5,
    progressBarText:'Step 6 of 8',
    nextButtonText:'Next',
    backButtonText:'Back',
}

export const stepSevenLabels={
    introLabel:'7. Hire Agreement',
    introSubLabel:'Please read the following carefully, when you are happy you can digitally sign the hire agreement below: ',
    introSubLabelHint:'To complete your application, we need you to read through and accept the Hire Agreement below.',
    subHeadingTextLabel: 'Please read the following carefully, when you are happy, you can digitally sign the hire agreement below.',
    checkboxLabel:'Click here to accept the contract agreement',
    progressBarCount:6,
    progressBarText:'Step 7 of 8',
    nextButtonText:'Next',
    backButtonText:'Back',
}
export const stepEightLabels={
    introLabel:'8. Salary Sacrifice Agreement',
    introSubLabel:'You’re almost there! Please read the following carefully, when you are happy you can digitally sign the salary sacrifice agreement below: ',
    introSubLabelHint:'By signing the salary sacrifice agreement, you are consenting to your employer to deduct the agreed amount from your salary for the agreed time period.',
    subHeadingTextLabel: 'Please read the following carefully, when you are happy, you can digitally sign the hire agreement below.',
    checkboxLabel:'Click here to accept the contract agreement',
    progressBarCount:7,
    progressBarText:'Step 8 of 8',
    nextButtonText:'Next',
    backButtonText:'Back',
}

export const hireAgreementContent ={    
    line1:"Ref: 321s2132s1a2s1",
    line2:"HIRE AGREEMENT REGULATED BY THE CONSUMER CREDIT ACT 1974",
    line3Part1:"Hire Agreement between: ",
    line3Part2:" And Halfords Ltd",
    line4Part1:"The Customer: ",
    line4Part2:" of ",
    line4Part3:" and with an email address of ",
    line4Part4:" (“you”); and ",
    line5:"The Hirer: Halfords Limited (trading as Cycle2Work) Company No 00103161 whose registered office is at Icknield Street Drive, Washford West, Redditch, Worcestershire B98 0DE (the “Hirer” or “we”).",
    line6:"Key Financial Information Amount of Payment(s): Cycle2Work will accept as payment for the hire of the Goods for the Fixed Period, the Letter of Collection provided to you for this purpose by the Hirer. Commencement Date: means the date that you submit the application and agreed to the terms and conditions of this agreement.",
    line7:"Fixed Period: 12 months from the Commencement Date. Goods: bicycle and cycling safety equipment. Letter of Collection a letter prepared by the Hirer and provided to the Customer which sets out the maximum value of the Goods to be hired for the Fixed Period.",
    line8:"KEY INFORMATION Under this Hire Agreement, the Goods do not become your property and you must not profit from, transfer or otherwise dispose of them. You must pay for the value of the Goods if they are lost, stolen or destroyed.",
    line9:"You must also pay for any damage to the Goods other than reasonable wear and tear. For further information, see the 'Customer Obligations' section in the Terms and Conditions below. If you default on your obligations under this Hire Agreement (e.g. by failing to make any payment when due), the Hirer may recover from you the reasonable legal, administrative and other costs and expenses incurred in enforcing the Hirer’s rights under this Hire Agreement.",
    line10:"MISSING PAYMENTS",
    line11:"Missing payments could have severe consequences and may make obtaining future credit more difficult for you.",
    line12:"IMPORTANT - READ THIS CAREFULLY TO FIND OUT ABOUT YOUR RIGHTS",
    line13:"The Consumer Credit Act 1974 covers this Hire Agreement and lays down certain requirements for your protection which should have been complied with when this Hire Agreement was made. If they were not, the Hirer cannot enforce this Hire Agreement against you without getting a court order. If you would like to know more about your rights under the Act, contact either your local Trading Standards Department or your nearest Citizens' Advice Bureau."
}

export const additionalQuestionsLabels= {
    introLabel:'Additional Questions',
    introSubLabel:'Your employer has asked us to find out the below: ',
    introSubLabelHint:'The questions in this section have been written by your employer.',
    subHeadingTextLabel: 'Your company has asked us to find out the below',
    selectDefaultOption:'Choose from below',
    progressBarCount:3,
    progressBarText:'Step 4 of 8',
    nextButtonText:'Next',
    backButtonText:'Back',
}

export const thankYouScreenLabels = {
    introLabel:'9. Thank you for your application',
    introSubLabel:'',
    introSubLabelHint:'to be discussed',
    headingLine:"A confirmation email will be sent to ",
    confirmLine1:"Your employer will now review your application.",
    confirmLine2:"Once approved, you will be emailed your Letter of Collection.",
    confirmLine3:"You will then be able to choose where you want to redeem and get your new cycling gear!",
    referenceLine:"Your Reference No.",
    marketingLabel:'Halfords Marketing',
    marketingInterestLabel:'Would you like to hear from us on our latest offers and promotions?',
    marketingSubTextLabel:'We would like to keep you up to date with offers and schemes. If you would like to be kept informed of new offers and schemes, please check the preferred communication method.',
    closeButtonRedirectURL:'https://www.halfords.com/cycling/expert-advice/cycle2work.html',
    progressBarCount:8,
    progressBarText:'Completed',
    closeButtonText:'Close'

}

