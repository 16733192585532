import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stepEightLabels } from '../components/ScreenLabels';
import HireAgreementDocusign from '../components/HireAgreementDocusign';
import ProgressBar from '../components/ProgressBar';
import { useEffect } from "react";
const StepEight = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });      
  },[]);
  return (
    <div className="form-element">
      <div className="start-text text-center">
        <ProgressBar progress={stepEightLabels.progressBarCount} />
        <span>{stepEightLabels.progressBarText} </span>
      </div>
      <div className="intro-label-new"> {stepEightLabels.introLabel} </div>
      <div className="text-left">
        <label className="intro-sub-label">{stepEightLabels.introSubLabel}
          &nbsp;&nbsp;
          <span className="tooltip-icon">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span className="tooltiptext">{stepEightLabels.introSubLabelHint}</span>
          </span>
        </label>
      </div>
      <form className="text-left">
       
        <div>
          <HireAgreementDocusign />
        </div>       
      </form>
    </div>
  );
};

export default StepEight;


