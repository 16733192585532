import React, { useState, useContext, useEffect } from "react";
import { Context } from "../components/Context";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { additionalQuestionsLabels } from "../components/ScreenLabels";
import ProgressBar from "../components/ProgressBar";
import NetworkError from "../components/NetworkError";

const AdditionalQuestions = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });      
  },[]);
  const [context, setContext] = useContext(Context);
  let selectDefaultOption = additionalQuestionsLabels.selectDefaultOption;
  const [qa1, setQa1] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeSelectedAnswer1
      : selectDefaultOption
  );
  const [qa2, setQa2] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeSelectedAnswer2
      : selectDefaultOption
  );
  const [qa3, setQa3] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeSelectedAnswer3
      : selectDefaultOption
  );
  const [qa4, setQa4] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeSelectedAnswer4
      : selectDefaultOption
  );
  const [qa5, setQa5] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeSelectedAnswer5
      : selectDefaultOption
  );

  const [qa1Text, setQa1Text] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeAnswer1Other
      : ""
  );
  const [qa2Text, setQa2Text] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeAnswer2Other
      : ""
  );
  const [qa3Text, setQa3Text] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeAnswer3Other
      : ""
  );
  const [qa4Text, setQa4Text] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeAnswer4Other
      : ""
  );
  const [qa5Text, setQa5Text] = useState(
    context.addQuesSelectedData
      ? context.addQuesSelectedData.BespokeAnswer5Other
      : ""
  );

  let qa1TextBoolValue = false,
    qa2TextBoolValue = false,
    qa3TextBoolValue = false,
    qa4TextBoolValue = false,
    qa5TextBoolValue = false;
  if (context.addQuesSelectedData) {
    qa1TextBoolValue = context.addQuesSelectedData.BespokeAnswer1Other.length
      ? true
      : false;
    qa2TextBoolValue = context.addQuesSelectedData.BespokeAnswer2Other.length
      ? true
      : false;
    qa3TextBoolValue = context.addQuesSelectedData.BespokeAnswer3Other.length
      ? true
      : false;
    qa4TextBoolValue = context.addQuesSelectedData.BespokeAnswer4Other.length
      ? true
      : false;
    qa5TextBoolValue = context.addQuesSelectedData.BespokeAnswer5Other.length
      ? true
      : false;
  }

  const [qa1TextBool, setQa1TextBool] = useState(qa1TextBoolValue);
  const [qa2TextBool, setQa2TextBool] = useState(qa2TextBoolValue);
  const [qa3TextBool, setQa3TextBool] = useState(qa3TextBoolValue);
  const [qa4TextBool, setQa4TextBool] = useState(qa4TextBoolValue);
  const [qa5TextBool, setQa5TextBool] = useState(qa5TextBoolValue);

  const [qa1TextEmptyBool, setQa1TextEmptyBool] = useState(false);
  const [qa2TextEmptyBool, setQa2TextEmptyBool] = useState(false);
  const [qa3TextEmptyBool, setQa3TextEmptyBool] = useState(false);
  const [qa4TextEmptyBool, setQa4TextEmptyBool] = useState(false);
  const [qa5TextEmptyBool, setQa5TextEmptyBool] = useState(false);
  const [networkErrBool, setNetworkErrBool] = useState(false);

  const data = context.stepOneResponse;

  const a1 = data.BespokeAnswer1 !== "" ? data.BespokeAnswer1.split(",") : [];
  const a2 = data.BespokeAnswer2 !== "" ? data.BespokeAnswer2.split(",") : [];
  const a3 = data.BespokeAnswer3 !== "" ? data.BespokeAnswer3.split(",") : [];
  const a4 = data.BespokeAnswer4 !== "" ? data.BespokeAnswer4.split(",") : [];
  const a5 = data.BespokeAnswer5 !== "" ? data.BespokeAnswer5.split(",") : [];

  const disableNextButton = () => {
    let qa1BoolValue = false,
      qa2BoolValue = false,
      qa3BoolValue = false,
      qa4BoolValue = false,
      qa5BoolValue = false;

    if (data.BespokeQuesiton1 !== "") {
      qa1BoolValue = qa1 !== selectDefaultOption ? false : true;
    } else {
      qa1BoolValue = false;
    }
    if (data.BespokeQuesiton2 !== "") {
      qa2BoolValue = qa2 !== selectDefaultOption ? false : true;
    } else {
      qa2BoolValue = false;
    }
    if (data.BespokeQuesiton3 !== "") {
      qa3BoolValue = qa3 !== selectDefaultOption ? false : true;
    } else {
      qa3BoolValue = false;
    }
    if (data.BespokeQuesiton4 !== "") {
      qa4BoolValue = qa4 !== selectDefaultOption ? false : true;
    } else {
      qa4BoolValue = false;
    }
    if (data.BespokeQuesiton5 !== "") {
      qa5BoolValue = qa5 !== selectDefaultOption ? false : true;
    } else {
      qa5BoolValue = false;
    }
    let bool =
      !qa1BoolValue &&
      !qa2BoolValue &&
      !qa3BoolValue &&
      !qa4BoolValue &&
      !qa5BoolValue &&
      !qa1TextEmptyBool &&
      !qa2TextEmptyBool &&
      !qa3TextEmptyBool &&
      !qa4TextEmptyBool &&
      !qa5TextEmptyBool
        ? false
        : true;

    return bool;
  };
  let bool = disableNextButton();

  const selectOnChange = (val, index) => {
    val = val.trim();
    switch (index) {
      case 1:
        setQa1(val);
        if (val.toLowerCase() === "other" || val.toLowerCase() === "others") {
          if(!qa1TextBool) setQa1TextBool(true);
          qa1Text !== ""
            ? setQa1TextEmptyBool(false)
            : setQa1TextEmptyBool(true);
        } else {
          if(qa1TextBool) setQa1TextBool(false);
          if(qa1TextEmptyBool) setQa1TextEmptyBool(false);
          setQa1Text("");
        }
        break;

      case 2:
        setQa2(val);
        if (val.toLowerCase() === "other" || val.toLowerCase() === "others") {
          if(!qa2TextBool) setQa2TextBool(true);
          qa2Text !== ""
            ? setQa2TextEmptyBool(false)
            : setQa2TextEmptyBool(true);
        } else {
          if(qa2TextBool) setQa2TextBool(false);
          if(qa2TextEmptyBool) setQa2TextEmptyBool(false);
          setQa2Text("");
        }
        break;
      case 3:
        setQa3(val);
        if (val.toLowerCase() === "other" || val.toLowerCase() === "others") {
          if(!qa3TextBool) setQa3TextBool(true);
          qa3Text !== ""
            ? setQa3TextEmptyBool(false)
            : setQa3TextEmptyBool(true);
        } else {
          if(qa3TextBool) setQa3TextBool(false);
          if(qa3TextEmptyBool) setQa3TextEmptyBool(false);
          setQa3Text("");
        }
        break;
      case 4:
        setQa4(val);
        if (val.toLowerCase() === "other" || val.toLowerCase() === "others") {
          if(!qa4TextBool) setQa4TextBool(true);
          qa4Text !== ""
            ? setQa4TextEmptyBool(false)
            : setQa4TextEmptyBool(true);
        } else {
          if(qa4TextBool) setQa4TextBool(false);
          if(qa4TextEmptyBool) setQa4TextEmptyBool(false);
          setQa4Text("");
        }
        break;
      case 5:
        setQa5(val);
        if (val.toLowerCase() === "other" || val.toLowerCase() === "others") {
          if(!qa5TextBool) setQa5TextBool(true);
          qa5Text !== ""
            ? setQa5TextEmptyBool(false)
            : setQa5TextEmptyBool(true);
        } else {
          if(qa5TextBool) setQa5TextBool(false);
          if(qa5TextEmptyBool) setQa5TextEmptyBool(false);
          setQa5Text("");
        }
        break;
      default:
        break;
    }
  };

  const addQuesSelectedData = {
    BespokeAnswer1Other: qa1Text,
    BespokeAnswer2Other: qa2Text,
    BespokeAnswer3Other: qa3Text,
    BespokeAnswer4Other: qa4Text,
    BespokeAnswer5Other: qa5Text,
    BespokeSelectedAnswer1: qa1,
    BespokeSelectedAnswer2: qa2,
    BespokeSelectedAnswer3: qa3,
    BespokeSelectedAnswer4: qa4,
    BespokeSelectedAnswer5: qa5,
  };
  const handleClick = (e) => {
    e.preventDefault();
    const addQuesPostData = {
      ...addQuesSelectedData,
      AdditionalQAExisted: context.stepThreePostData.AdditionalQAExisted,
      "Alredy Existed": context.stepThreePostData["Alredy Existed"],
      BespokeQuesiton1: context.stepThreePostData.BespokeQuesiton1,
      BespokeQuesiton2: context.stepThreePostData.BespokeQuesiton2,
      BespokeQuesiton3: context.stepThreePostData.BespokeQuesiton3,
      BespokeQuesiton4: context.stepThreePostData.BespokeQuesiton4,
      BespokeQuesiton5: context.stepThreePostData.BespokeQuesiton5,
      ContactID: context.stepThreePostData.ContactID,
      Emailcode: context.stepThreePostData.Emailcode,
      SchemeMax: context.stepThreePostData.SchemeMax,
      SchemeMin: context.stepThreePostData.SchemeMin,
      SchemeValid: context.stepThreePostData.SchemeValid,
      address: context.stepThreePostData.address,
      addressLine1: context.stepThreePostData.addressLine1,
      addressLine2: context.stepThreePostData.addressLine2,
      addressLine3: context.stepThreePostData.addressLine3,
      addressTown: context.stepThreePostData.addressTown,
      addressPostCode: context.stepThreePostData.addressPostCode,
      dob: context.stepThreePostData.dob,
      email: context.stepThreePostData.email,
      mobile: context.stepThreePostData.mobile,
      name: context.stepThreePostData.name,
      employeeFirstName: context.stepThreePostData.employeeFirstName,
      employeeLastName: context.stepThreePostData.employeeLastName,
      payroll: context.stepThreePostData.payroll,
      schemecode: context.stepThreePostData.schemecode,
      schemename: context.stepThreePostData.schemename,
      Frequency: context.stepFourPostData.Frequency,
      LoCVal: context.stepFourPostData.LoCVal,
      requiresGuarantor: context.stepThreePostData.requiresGuarantor,
      hireAgreement: false,
    };
    setContext({
      ...context,
      step: 5,
      addQuesSelectedData: addQuesSelectedData,
    });
  };

  
  
  return (
    <div className="form-element">
      {networkErrBool && <NetworkError setNetworkPopup={setNetworkErrBool}/>}
      <div className="start-text text-center">
        <ProgressBar progress={additionalQuestionsLabels.progressBarCount} />
        <span>{additionalQuestionsLabels.progressBarText} </span>
      </div>
      <div className="intro-label-new">
        {" "}
        {additionalQuestionsLabels.introLabel}{" "}
      </div>
      <div className="text-left">
        <label className="intro-sub-label">
          {additionalQuestionsLabels.introSubLabel}
          &nbsp;&nbsp;
          <span className="tooltip-icon">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span className="tooltiptext">
              {additionalQuestionsLabels.introSubLabelHint}
            </span>
          </span>
        </label>
      </div>
      <form className="text-left">
        {data.BespokeQuesiton1 !== "" ? (
          <div className="form-group">
            <label>{data.BespokeQuesiton1}</label>
            <span className="asterisk-element">*</span>
            <br />
            <select
              name="qa1Select"
              id="qa1Select"
              onChange={(e) => selectOnChange(e.target.value, 1)}
              autoFocus
              disabled={
                qa2TextEmptyBool ||
                qa3TextEmptyBool ||
                qa4TextEmptyBool ||
                qa5TextEmptyBool
              }
            >
              {qa1 === selectDefaultOption ? (
                <option defaultValue={selectDefaultOption}>
                  {selectDefaultOption}
                </option>
              ) : null}
              {a1.map((item, index) => {
                if (item.trim() === qa1) {
                  return (
                    <option key={index} defaultValue={item}>
                      {item}
                    </option>
                  );
                }
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
            {qa1TextBool && (
              <input
                className="form-control other-input-field"
                type="text"
                onChange={(e) => {
                  let val = e.target.value;
                  val !== ""
                    ? setQa1TextEmptyBool(false)
                    : setQa1TextEmptyBool(true);
                  setQa1Text(val);
                }}
                value={qa1Text}
                readOnly={
                  qa2TextEmptyBool ||
                  qa3TextEmptyBool ||
                  qa4TextEmptyBool ||
                  qa5TextEmptyBool
                }
                required
                autoFocus={qa1TextBool}
              />
            )}
          </div>
        ) : null}
        {data.BespokeQuesiton2 !== "" ? (
          <div className="form-group">
            <label>{data.BespokeQuesiton2}</label>
            <span className="asterisk-element">*</span>
            <br />
            <select
              name="qa2Select"
              id="qa2Select"
              onChange={(e) => selectOnChange(e.target.value, 2)}
              disabled={
                qa1TextEmptyBool ||
                qa3TextEmptyBool ||
                qa4TextEmptyBool ||
                qa5TextEmptyBool
              }
            >
              {qa2 === selectDefaultOption ? (
                <option defaultValue={selectDefaultOption}>
                  {selectDefaultOption}
                </option>
              ) : null}
              {a2.map((item, index) => {
                if (item.trim() === qa2) {
                  return (
                    <option key={index} defaultValue={item}>
                      {item}
                    </option>
                  );
                }
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
            {qa2TextBool && (
              <input
                className="form-control other-input-field"
                type="text"
                onChange={(e) => {
                  let val = e.target.value;
                  val !== ""
                    ? setQa2TextEmptyBool(false)
                    : setQa2TextEmptyBool(true);
                  setQa2Text(val);
                }}
                value={qa2Text}
                readOnly={
                  qa1TextEmptyBool ||
                  qa3TextEmptyBool ||
                  qa4TextEmptyBool ||
                  qa5TextEmptyBool
                }
                required
                autoFocus={qa2TextBool}
              />
            )}
          </div>
        ) : null}
        {data.BespokeQuesiton3 !== "" ? (
          <div className="form-group">
            <label>{data.BespokeQuesiton3}</label>
            <span className="asterisk-element">*</span>
            <br />
            <select
              name="qa3Select"
              id="qa3Select"
              onChange={(e) => selectOnChange(e.target.value, 3)}
              disabled={
                qa1TextEmptyBool ||
                qa2TextEmptyBool ||
                qa4TextEmptyBool ||
                qa5TextEmptyBool
              }
            >
              {qa3 === selectDefaultOption ? (
                <option defaultValue={selectDefaultOption}>
                  {selectDefaultOption}
                </option>
              ) : null}
              {a3.map((item, index) => {
                if (item.trim() === qa3) {
                  return (
                    <option key={index} defaultValue={item}>
                      {item}
                    </option>
                  );
                }
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
            {qa3TextBool && (
              <input
                className="form-control other-input-field"
                type="text"
                onChange={(e) => {
                  let val = e.target.value;
                  val !== ""
                    ? setQa3TextEmptyBool(false)
                    : setQa3TextEmptyBool(true);
                  setQa3Text(val);
                }}
                value={qa3Text}
                readOnly={
                  qa1TextEmptyBool ||
                  qa2TextEmptyBool ||
                  qa4TextEmptyBool ||
                  qa5TextEmptyBool
                }
                required
                autoFocus={qa3TextBool}
              />
            )}
          </div>
        ) : null}
        {data.BespokeQuesiton4 !== "" ? (
          <div className="form-group">
            <label>{data.BespokeQuesiton4}</label>
            <span className="asterisk-element">*</span>
            <br />
            <select
              name="qa4Select"
              id="qa4Select"
              onChange={(e) => selectOnChange(e.target.value, 4)}
              disabled={
                qa1TextEmptyBool ||
                qa2TextEmptyBool ||
                qa3TextEmptyBool ||
                qa5TextEmptyBool
              }
            >
              {qa4 === selectDefaultOption ? (
                <option defaultValue={selectDefaultOption}>
                  {selectDefaultOption}
                </option>
              ) : null}
              {a4.map((item, index) => {
                if (item.trim() === qa4) {
                  return (
                    <option key={index} defaultValue={item}>
                      {item}
                    </option>
                  );
                }
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
            {qa4TextBool && (
              <input
                className="form-control other-input-field"
                type="text"
                onChange={(e) => {
                  let val = e.target.value;
                  val !== ""
                    ? setQa4TextEmptyBool(false)
                    : setQa4TextEmptyBool(true);
                  setQa4Text(val);
                }}
                value={qa4Text}
                readOnly={
                  qa1TextEmptyBool ||
                  qa2TextEmptyBool ||
                  qa3TextEmptyBool ||
                  qa5TextEmptyBool
                }
                required
                autoFocus={qa4TextBool}
              />
            )}
          </div>
        ) : null}
        {data.BespokeQuesiton5 !== "" ? (
          <div className="form-group">
            <label>{data.BespokeQuesiton5}</label>
            <span className="asterisk-element">*</span>
            <br />
            <select
              name="qa5Select"
              id="qa5Select"
              onChange={(e) => selectOnChange(e.target.value, 5)}
              disabled={
                qa1TextEmptyBool ||
                qa2TextEmptyBool ||
                qa3TextEmptyBool ||
                qa4TextEmptyBool
              }
            >
              {qa5 === selectDefaultOption ? (
                <option defaultValue={selectDefaultOption}>
                  {selectDefaultOption}
                </option>
              ) : null}
              {a5.map((item, index) => {
                if (item.trim() === qa5) {
                  return (
                    <option key={index} defaultValue={item}>
                      {item}
                    </option>
                  );
                }
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </select>
            {qa5TextBool && (
              <input
                className="form-control other-input-field"
                type="text"
                onChange={(e) => {
                  let val = e.target.value;
                  val !== ""
                    ? setQa5TextEmptyBool(false)
                    : setQa5TextEmptyBool(true);
                  setQa5Text(val);
                }}
                value={qa5Text}
                readOnly={
                  qa1TextEmptyBool ||
                  qa2TextEmptyBool ||
                  qa3TextEmptyBool ||
                  qa4TextEmptyBool
                }
                required
                autoFocus={qa5TextBool}
              />
            )}
          </div>
        ) : null}
       
        <div className="button-group-element">
          <button
            type="button"
            className={"btn next-enable-button-element back-button"}
            onClick={() => setContext({ ...context, step: 4 })}
          >
            {additionalQuestionsLabels.backButtonText}
          </button>
          <button
            type="submit"
            disabled={bool}
            className={
              "btn green-btn next-button " +
              (bool === true
                ? "next-disable-button-element"
                : "next-enable-button-element")
            }
            onClick={(e) => handleClick(e)}
          >
            {additionalQuestionsLabels.nextButtonText}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdditionalQuestions;
