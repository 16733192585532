import React, { useContext, useEffect, useState } from "react";
import { Context } from "../components/Context";
import axios from "axios";
import { faCircleExclamation, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stepFourLabels, stepFourOtherInfo } from "../components/ScreenLabels";
import { stepFourAPIURL, hireAgreementAPIUrl } from "../components/ApiUrls";
import ProgressBar from "../components/ProgressBar";
import bike from "../assets/images/bike.svg";
import lock from "../assets/images/c2w-lock.svg";
import helmet from "../assets/images/c2w-helmet.svg";
import NetworkError from "../components/NetworkError";
const StepFour = () => {
  const [context, setContext] = useContext(Context);
  const [networkErrBool, setNetworkErrBool] = useState(false);
  const [limit, setLimit] = useState(
    context.stepFourPostData ? context.stepFourPostData.LoCVal : ""
  );
  const [bikeLimit, setBikeLimit] = useState(
    context.stepFourPostData ? context.stepFourPostData.bikeLimit : ""
  );
  const [helmetLimit, setHelmetLimit] = useState(
    context.stepFourPostData ? context.stepFourPostData.helmetLimit : ""
  );
  const [accessoriesLimit, setAccessoriesLimit] = useState(
    context.stepFourPostData ? context.stepFourPostData.accessoriesLimit : ""
  );
  const [frequency, setFrequency] = useState(
    context.stepFourPostData ? context.stepFourPostData.Frequency : ""
  );
  const [limitBool, setLimitBool] = useState(false);
  const [loaderBool, setLoaderBool] = useState(false);
  const [limitEmptyBool, setLimitEmptyBool] = useState(false);
  const [showLimitBool, setShowLimitBool] = useState(false);
  const SchemeMin = context.stepOneResponse
    ? context.stepOneResponse.SchemeMin
    : 100;
  const SchemeMax = context.stepOneResponse
    ? context.stepOneResponse.SchemeMax
    : 1000;
  const [hireAgreementDetails, setHireAgreementDetails] = useState(
    context.hireAgreementDetails ? context.hireAgreementDetails : {}
  );
  const [agreementBool, setAgreementBool] = useState(false);
  const [stepFourResponseBool, setStepFourResponseBool] = useState(false);
  const [stepFourPostData, setStepFourPostData] = useState(
    context.stepFourPostData ? context.stepFourPostData : {}
  );
  const [stepFourResponse, setStepFourResponse] = useState(
    context.stepFourResponse ? context.stepFourResponse : {}
  );
  const [bool, setBool] = useState(context.stepFourResponse ? false : true);
  let currencySymbol =  context.currencySymbol?context.currencySymbol:"£"; 
  console.log(window.innerWidth);
  let screenWidthMobileBool = window.innerWidth <= 567 ? true : false;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });      
  },[]);
  useEffect(() => {
    if (agreementBool && stepFourResponseBool) {
      navigateNextScreen();
    }
  }, [agreementBool, stepFourResponseBool]);
  const getToken = async ()=>{ 
    let tokenObj={}      
    await axios.get('/post')
    .then(res => {tokenObj=res.data})
    .catch(e=>{console.log(e)});   
    return tokenObj;     
  }
  const getHireAgreementDetails = (headers) => {
    let hireAgreementParams = {};
    hireAgreementParams["email"] = context.stepOnePostData.email;
    hireAgreementParams["name"] = context.stepOnePostData.name;
    hireAgreementParams["schemecode"] = context.stepOnePostData.schemecode;
    axios({
      url: hireAgreementAPIUrl,
      method: "POST",
      data: hireAgreementParams,
      headers:headers
    })
      .then((resp) => {
        let response = resp.data;
        let json = {};
        json["hireAgreementAccountid"] =
          response.ssp_hireagreementtemplateaccountid;
        json["hireAgreementClickwrapid"] =
          response.ssp_hireagreementtemplateclickwrapid;
        json["preHireAgreementAccountid"] =
          response.ssp_prehireagreementtemplateaccountid;
        json["preHireAgreementClickwrapid"] =
          response.ssp_prehireagreementtemplateclickwrapid;
        json["salSacAgreementAccountid"] =
          response.ssp_sshireagreementtemplateaccountid;
        json["salSacAgreementClickwrapid"] =
          response.ssp_sshireagreementtemplateclickwrapid;
        json["agreementTime"] = new Date().getTime();
        json["agreementNumber"] = response.agreementNumber;
        json["companyName"] = response.companyName;
        json["mainContact"] = response.mainContact;
        setHireAgreementDetails(json);
        if (!agreementBool) setAgreementBool(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigateNextScreen = () => {
    if (
      context.stepOneResponse.AdditionalQAExisted === "Yes" &&
      !context.changeAmount
    ) {
      setContext({
        ...context,
        step: 9,
        stepFourPostData: stepFourPostData,
        stepFourResponse: stepFourResponse,
        hireAgreementDetails: hireAgreementDetails,
      });
    } else {
      setContext({
        ...context,
        step: 5,
        stepFourPostData: stepFourPostData,
        stepFourResponse: stepFourResponse,
        hireAgreementDetails: hireAgreementDetails,
      });
    }
  };
  
  const getStepFourResponse = (headers) => {
    let term = "12 monthly";
    switch (frequency) {
      case "Weekly":
        term = "52 Weekly";
        break;
      case "Fortnightly":
        term = "26 Fortnightly";
        break;
      case "4 Weekly":
        term = "13 Weekly";
        break;
      case "Monthly":
        term = "12 Monthly";
        break;
      default:
        break;
    }
    if (limit >= SchemeMin && limit <= SchemeMax) {
      const postData = {
        SchemeCode: context.stepOnePostData.schemecode,
        LoCVal: parseInt(limit),
        Frequency: frequency,
        bikeLimit: bikeLimit,
        helmetLimit: helmetLimit,
        accessoriesLimit: accessoriesLimit,
        term: term,
      };
      setStepFourPostData(postData);
      postApiForStepFour(postData,headers);
    }
  };
  const handleClick = async(e) => {
    e.preventDefault();

    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey            
    }    
    if (!loaderBool) setLoaderBool(true);
    !context.hireAgreementDetails
      ? getHireAgreementDetails(headers)
      : setAgreementBool(true);
    if (context.stepFourResponse) {
      if (
        context.stepFourResponse.LoCVal === parseInt(limit) &&
        context.stepFourResponse.Frequency === frequency
      ) {
        if (!stepFourResponseBool) setStepFourResponseBool(true);
      } else {
        getStepFourResponse(headers);
      }
    } else {
      getStepFourResponse(headers);
    }
  };
  const postApiForStepFour = (postData,headers) => {
    axios({
      url: stepFourAPIURL,
      method: "POST",
      data: postData,
      headers:headers
    })
      .then((res) => {
        if (networkErrBool) {
          setNetworkErrBool(false);
        }
        let response = res.data;
        setStepFourResponse(response);
        if (!stepFourResponseBool) setStepFourResponseBool(true);
      })
      .catch((err) => {
        console.log("err");
        err.code === "ERR_NETWORK"
          ? setNetworkErrBool(true)
          : setNetworkErrBool(false);
      });
  };
  const calculateLimit = () => {
    let bl,
      hl,
      al = 0;
    bikeLimit === "" ? (bl = 0) : (bl = parseInt(bikeLimit));
    helmetLimit === "" ? (hl = 0) : (hl = parseInt(helmetLimit));
    accessoriesLimit === "" ? (al = 0) : (al = parseInt(accessoriesLimit));
    let val = bl + hl + al;
    return val;
  };

  const validateLimit = (val, frequencyBool) => {
    if (val === 0) {
      if (!limitEmptyBool) setLimitEmptyBool(true);
      setLimit("");
      if (!bool) setBool(true);
    } else if (val === "") {
      setLimit("");
      if (!bool) setBool(true);
    } else if (val >= SchemeMin && val <= SchemeMax) {
      if (limitBool) setLimitBool(false);
      setLimit(val);
      if (bool && frequencyBool) setBool(false);
    } else {
      if (!limitBool) setLimitBool(true);
      setLimit("");
      if (!bool) setBool(true);
    }
  };
  const limitOnBlur = (frequencyBool) => {
    let val = calculateLimit();
    validateLimit(val, frequencyBool);
  };

  const limitOnChange = (e, word) => {
    if (!showLimitBool) setShowLimitBool(true);
    let bl,
      hl,
      al = 0;
    let val = e.target.value;

    if (!/[^0-9]/g.test(val)) {
      if (word === "bike") {
        val !== "" ? (bl = parseInt(val)) : (bl = 0);
        helmetLimit === "" ? (hl = 0) : (hl = parseInt(helmetLimit));
        accessoriesLimit === "" ? (al = 0) : (al = parseInt(accessoriesLimit));
        let sum = bl + hl + al;
        sum <= SchemeMax ? setBikeLimit(val) : setLimitBool(true);
      } else if (word === "helmet") {
        bikeLimit === "" ? (bl = 0) : (bl = parseInt(bikeLimit));
        val !== "" ? (hl = parseInt(val)) : (hl = 0);
        accessoriesLimit === "" ? (al = 0) : (al = parseInt(accessoriesLimit));
        let sum = bl + hl + al;
        sum <= SchemeMax ? setHelmetLimit(val) : setLimitBool(true);
      } else if (word === "accessories") {
        bikeLimit === "" ? (bl = 0) : (bl = parseInt(bikeLimit));
        helmetLimit === "" ? (hl = 0) : (hl = parseInt(helmetLimit));
        val !== "" ? (al = parseInt(val)) : (al = 0);
        let sum = bl + hl + al;
        sum <= SchemeMax ? setAccessoriesLimit(val) : setLimitBool(true);
      }
      let sum = bl + hl + al;
      if (sum <= SchemeMax) {
        setLimit(sum);
        if (limitBool) setLimitBool(false);
      } else {
        if (!limitBool) setLimitBool(true);
      }
      if (val >= SchemeMin && val <= SchemeMax) {
        if (bool && frequency !== "") setBool(false);
      } else {
        if (!bool) setBool(true);
      }
    }
  };
  const bikeLimitOnBlur = (e) => {
    e.target.placeholder = stepFourOtherInfo.limitPlaceholder;

    if (e.target.value === "0" || e.target.value === "") {
      setBikeLimit("");
    } else {
      if (frequency !== "") limitOnBlur(true);
    }
    if (limit >= SchemeMin && limit <= SchemeMax) {
      if (limitBool) setLimitBool(false);
    }
  };
  const helmetLimitOnBlur = (e) => {
    e.target.placeholder = stepFourOtherInfo.limitPlaceholder;
    if (e.target.value === "0" || e.target.value === "") setHelmetLimit("");
    if (frequency !== "") limitOnBlur(true);
    if (limit >= SchemeMin && limit <= SchemeMax) {
      if (limitBool) setLimitBool(false);
    }
  };
  const accessoriesLimitOnBlur = (e) => {
    e.target.placeholder = stepFourOtherInfo.limitPlaceholder;
    if (e.target.value === "0" || e.target.value === "")
      setAccessoriesLimit("");
    if (frequency !== "") limitOnBlur(true);
    if (limit >= SchemeMin && limit <= SchemeMax) {
      if (limitBool) setLimitBool(false);
    }
  };

  const limitOnFocus = (e) => {
    if(!loaderBool){
      e.target.placeholder = "";
      if (limitBool) setLimitBool(false);
      if (limitEmptyBool) setLimitEmptyBool(false);
    }
    
  };

  const frequencySelected = (e, item) => {
    e.preventDefault();
    setFrequency(item);
    limitOnBlur(true);
  };

  return (
    <div className="App">
      <div className="loader-bool-enable">
        {loaderBool ? (
          <>
            <div className="loader buttonLoaderdiv"> </div>
            <div className="loading-text buttonLoaderdivText">{stepFourOtherInfo.loaderText}</div>
          </>
        ) : null}
      </div>
      <div
        className={
          "form-element " + (loaderBool === true ? "blur-background" : "")
        }
      >
        {networkErrBool && <NetworkError setNetworkPopup={setNetworkErrBool} />}
        <div>
          <div className="start-text text-center">
            <ProgressBar progress={stepFourOtherInfo.progressBarCount} />
            <span>{stepFourOtherInfo.progressBarText} </span>
          </div>
          <div className="intro-label-new"> {stepFourLabels.introLabel} </div>
          <div className="text-left">
            <label className="intro-sub-label">
              {stepFourLabels.introSubLabel}
              &nbsp;&nbsp;
              <span className="tooltip-icon">
                <FontAwesomeIcon icon={faCircleExclamation} />
                <span className="tooltiptext">
                  {stepFourLabels.introSubLabelHint}
                </span>
              </span>
            </label>
          </div>
          <form>
            <div className="form-group text-left">
              <label className="custom-text-justify">
                {stepFourLabels.subHeadingTextLabelPart1}{" "}
                <b>
                  {currencySymbol}
                  <span>{SchemeMin}</span>
                </b>{" "}
                {stepFourLabels.subHeadingTextLabelPart2}{" "}
                <b>
                  {currencySymbol}
                  <span>{SchemeMax}</span>
                </b>
              </label>
            </div>            
            <div className="mb-4 text-center font-weight-bold">
              I want to spend
            </div>
            {screenWidthMobileBool ? (
              <div>               
                <div className="row pl-1 pr-3">
                  <div className="col">
                    <div className="row">
                      <img
                        className="m-auto spend-image"
                        src={bike}
                        alt="bike"
                      />
                    </div>
                    <div className="row">
                      <label className="m-auto">Bike</label>
                    </div>
                  </div>
                  <div className="row m-auto">                    
                    {currencySymbol}
                    <span className="ml-2">
                      <input
                        type="text"
                        autoComplete="no"
                        autoFocus
                        className={
                          limitEmptyBool
                            ? "form-control small-text-field border-danger"
                            : "form-control small-text-field"
                        }
                        style={{ width: "85%" }}
                        placeholder={stepFourOtherInfo.limitPlaceholder}
                        min={SchemeMin}
                        max={SchemeMax}
                        value={bikeLimit}
                        onChange={(e) => limitOnChange(e, "bike")}
                        onBlur={(e) => bikeLimitOnBlur(e)}
                        onFocus={(e) => limitOnFocus(e)}
                        readOnly={loaderBool}
                      />
                    </span>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-sm-1 m-auto">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
                <div className="row pl-1 pr-3">
                  <div className="col">
                    <div className="row">
                      <img
                        className="m-auto spend-image"
                        src={helmet}
                        alt="helmet"
                      />
                    </div>
                    <div className="row">
                      <label className="m-auto">Helmet</label>
                    </div>
                  </div>
                  <div className="row m-auto">
                    {currencySymbol}
                    <span className="ml-2">
                      <input
                        type="text"
                        autoComplete="no"
                        className={
                          limitEmptyBool
                            ? "form-control small-text-field border-danger"
                            : "form-control small-text-field"
                        }
                        style={{ width: "85%" }}
                        placeholder={stepFourOtherInfo.limitPlaceholder}
                        min={SchemeMin}
                        max={SchemeMax}
                        value={helmetLimit}
                        onChange={(e) => limitOnChange(e, "helmet")}
                        onBlur={(e) => helmetLimitOnBlur(e)}
                        onFocus={(e) => limitOnFocus(e)}
                        readOnly={loaderBool}
                      />
                    </span>
                  </div>
                </div>
                <div className="row mt-2 mb-2">
                  <div className="col-sm-1 m-auto">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                </div>
                <div className="row mb-4 pl-1 pr-3">
                  <div className="col">
                    <div className="row">
                      <img
                        className="m-auto spend-image"
                        src={lock}
                        alt="lock"
                      />
                    </div>
                    <div className="row">
                      <label className="m-auto">Accessories</label>
                    </div>
                  </div>
                  <div className="row m-auto">
                    {currencySymbol}
                    <span className="ml-2">
                      <input
                        type="text"
                        autoComplete="no"
                        className={
                          limitEmptyBool
                            ? "form-control small-text-field border-danger"
                            : "form-control small-text-field"
                        }
                        style={{ width: "85%" }}
                        placeholder={stepFourOtherInfo.limitPlaceholder}
                        min={SchemeMin}
                        max={SchemeMax}
                        value={accessoriesLimit}
                        onChange={(e) => limitOnChange(e, "accessories")}
                        onBlur={(e) => accessoriesLimitOnBlur(e)}
                        onFocus={(e) => limitOnFocus(e)}
                        readOnly={loaderBool}
                      />
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="row mb-1">
                  <div className="col-sm">
                    <div className="row">
                      <img
                        className="m-auto spend-image"
                        src={bike}
                        alt="bike"
                      />
                    </div>
                    <div className="row">
                      <label className="m-auto">Bike</label>
                    </div>

                    <div className="row mt-3 mb-1">
                      <div className="col-sm">
                        <div className="row">
                          <div className="col-sm-1">
                            <label>{currencySymbol}</label>
                          </div>
                          <div className="col-sm">
                            <input
                              type="text"
                              autoComplete="no"
                              autoFocus
                              className={
                                limitEmptyBool
                                  ? "form-control small-text-field border-danger"
                                  : "form-control small-text-field"
                              }
                              placeholder={stepFourOtherInfo.limitPlaceholder}
                              min={SchemeMin}
                              max={SchemeMax}
                              value={bikeLimit}
                              onChange={(e) => limitOnChange(e, "bike")}
                              onBlur={(e) => bikeLimitOnBlur(e)}
                              onFocus={(e) => limitOnFocus(e)}
                              readOnly={loaderBool}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-1 m-auto">
                    <FontAwesomeIcon icon={faPlus} />
                  </div>

                  <div className="col-sm">
                    <div className="row">
                      <img
                        className="m-auto spend-image"
                        src={helmet}
                        alt="helmet"
                      />
                    </div>
                    <div className="row">
                      <label className="m-auto">Helmet</label>
                    </div>
                    <div className="row mt-3 mb-1">
                      <div className="col-sm">
                        <div className="row">
                          <div className="col-sm-1">
                            <label>{currencySymbol}</label>
                          </div>
                          <div className="col-sm">
                            <input
                              type="text"
                              autoComplete="no"
                              className={
                                limitEmptyBool
                                  ? "form-control small-text-field border-danger"
                                  : "form-control small-text-field"
                              }
                              placeholder={stepFourOtherInfo.limitPlaceholder}
                              min={SchemeMin}
                              max={SchemeMax}
                              value={helmetLimit}
                              onChange={(e) => limitOnChange(e, "helmet")}
                              onBlur={(e) => helmetLimitOnBlur(e)}
                              onFocus={(e) => limitOnFocus(e)}
                              readOnly={loaderBool}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-1 m-auto">
                    <FontAwesomeIcon icon={faPlus} className="text-center" />
                  </div>
                  <div className="col-sm">
                    <div className="row">
                      <img
                        className="m-auto spend-image"
                        src={lock}
                        alt="lock"
                      />
                    </div>
                    <div className="row">
                      <label className="m-auto">Accessories</label>
                    </div>
                    <div className="row mt-3 mb-1">
                      <div className="col-sm">
                        <div className="row">
                          <div className="col-sm-1">
                            <label>{currencySymbol}</label>
                          </div>
                          <div className="col-sm">
                            <input
                              type="text"
                              autoComplete="no"
                              className={
                                limitEmptyBool
                                  ? "form-control small-text-field border-danger"
                                  : "form-control small-text-field"
                              }
                              placeholder={stepFourOtherInfo.limitPlaceholder}
                              min={SchemeMin}
                              max={SchemeMax}
                              value={accessoriesLimit}
                              onChange={(e) => limitOnChange(e, "accessories")}
                              onBlur={(e) => accessoriesLimitOnBlur(e)}
                              onFocus={(e) => limitOnFocus(e)}
                              readOnly={loaderBool}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-left mb-3">
                  {showLimitBool && (
                    <>
                      <label style={{ fontStyle: "italic" }}>
                        Entered amount : {currencySymbol} <b>{limit}</b>
                      </label>
                      <br />
                    </>
                  )}
                  <>
                    {limitBool && (
                      <label className="error-message text-left">
                        {context.errorMessages.limitPart1
                          ? context.errorMessages.limitPart1
                          : context.errorMessagesLocal.limitPart1}{" "}
                        {currencySymbol}
                        {SchemeMin}{" "}
                        {context.errorMessages.limitPart2
                          ? context.errorMessages.limitPart2
                          : context.errorMessagesLocal.limitPart2}{" "}
                        {currencySymbol}
                        {SchemeMax}{" "}
                      </label>
                    )}
                  </>
                </div>
              </div>
            )}

            <div className="text-center">
              <div>
                <label className="font-weight-bold">
                  {" "}
                  {stepFourLabels.frequencyLabel}{" "}
                </label>
                <label className="font-weight-bold text-left">
                  {" "}
                  {stepFourLabels.frequencyLabel1}{" "}
                </label>
              </div>
              <div className="row filter-button-row">
                {stepFourLabels.frequencyOptions.map(function (item, i) {
                  return (
                    <div className="col-sm-3 filter-button-main" key={i}>
                      <button
                        className="filter-button"
                        style={
                          frequency === item
                            ? { backgroundColor: "#a6d2f2" }
                            : { backgroundColor: "#fff" }
                        }
                        onClick={(e) => {
                          frequencySelected(e, item);
                        }}
                        disabled={loaderBool}
                      >
                        {item}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="text-left">
                <label className="font-weight-bold mb-2">
                  {stepFourOtherInfo.otherInfoHeading}
                </label>
                <label>{stepFourOtherInfo.otherInfoText}</label>
              </div>
            </div>
            <div className="button-group-element">
            
              <button
                type="button"
                className={"btn next-enable-button-element back-button"}
                onClick={() => setContext({ ...context, step: 3 })}
                disabled={loaderBool}
              >
                {stepFourLabels.backButtonText}
              </button>
              <button
                type="submit"
                disabled={bool || loaderBool}
                className={
                  "btn green-btn next-button " +
                  (bool === true
                    ? "next-disable-button-element"
                    : "next-enable-button-element")
                }
                onClick={(e) => handleClick(e)}
              >
                {stepFourLabels.nextButtonText}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
