import React, { useContext, useEffect, useState } from "react";
import { Context } from "../components/Context";
import "../App.css";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { stepOneLabels, stepOneOtherInfo ,stepFourOtherInfo,errorMessagesLocal,schemecodeErrorMsg} from "../components/ScreenLabels";
import { stepOneAPIURL, errorMessageAPIURL } from "../components/ApiUrls";
import ProgressBar from "../components/ProgressBar";
import NetworkError from "../components/NetworkError";
import SAPidMissing from "../components/SAPidMissing";
import {
  thankyouScreenGetIpAPIURL,
  thankyouScreenOnLoadAPIURL,  
} from "../components/ApiUrls";

const StepOne = () => {
  var query = window.location.href;
  var url = new URL(query);
  var empCode = url.searchParams.get("empcode");
  let schemecodeFromURL = query.indexOf("?empcode=") > -1 ? empCode : "";
  var cidCode = url.searchParams.get("cid");
  let CIDFromURL = query.indexOf("cid=") > -1 ? cidCode : "";
  var pidCode = url.searchParams.get("pid");
  let PIDFromURL = query.indexOf("pid=") > -1 ? pidCode : "";

  //let schemecodeFromURL = query.indexOf("?empcode=") > -1 ? query.split("?empcode=")[1].split("?")[0].trim() : "";
  const [context, setContext] = useContext(Context);
  const [networkErrBool, setNetworkErrBool] = useState(false);
  const [firstName, setFirstName] = useState(
    context.stepOnePostData ? context.stepOnePostData.employeeFirstName : ""
  );
  const [surName, setSurName] = useState(
    context.stepOnePostData ? context.stepOnePostData.employeeLastName : ""
  );
  const [email, setEmail] = useState(
    context.stepOnePostData ? context.stepOnePostData.email : ""
  );
  const [confirmEmail, setConfirmEmail] = useState(
    context.stepOnePostData ? context.stepOnePostData.email : ""
  );
  const [schemecode, setSchemecode] = useState(
    context.stepOnePostData
      ? context.stepOnePostData.schemecode
      : schemecodeFromURL
  );
  

  const [errorMessages, setErrorMessages] = useState({});
  const [invalidFirstNameBool, setInvalidFirstNameBool] = useState(false);
  const [invalidSurNameBool, setInvalidSurNameBool] = useState(false);
  const [invalidEmailBool, setInvalidEmailBool] = useState(false);
  const [invalidConfirmEmailBool, setInvalidConfirmEmailBool] = useState(false);
  const [noMatchEmailBool, setNoMatchEmailBool] = useState(false);
  const [schemeInvalidBool, setSchemeInvalidBool] = useState(false);
  const [schemeBeforeBool, setSchemeBeforeBool] = useState(false);
  const [schemeAfterBool, setSchemeAfterBool] = useState(false);
  const [loaderBool, setLoaderBool] = useState(false);
  const [firstNameEmptyBool, setFirstNameEmptyBool] = useState(false);
  const [surNameEmptyBool, setSurNameEmptyBool] = useState(false);
  const [emailEmptyBool, setEmailEmptyBool] = useState(false);
  const [confirmEmailEmptyBool, setConfirmEmailEmptyBool] = useState(false);
  const [sapIdMissingBool, setSAPidMissingBool] = useState(false);
  const [stepOneResponseBool, setStepOneResponseBool] = useState(false);
  const [stepOneData, setStepOneData] = useState({});
  const [ipAddr, setIpAddr] = useState("");
  const [list, setList] = useState([]);
  const [checkedState, setCheckedState] = useState(
    new Array(list.length).fill(false)
  );
  const getToken = async ()=>{ 
    let tokenObj={}      
    await axios.get('/post')
    .then(res => {tokenObj=res.data})
    .catch(e=>{console.log(e)});   
    return tokenObj;     
  }
  const getListFromAPI = (headers) => {
    //API call
    axios({
      // Endpoint to send files
      url: thankyouScreenOnLoadAPIURL,
      method: "POST",
      headers:headers
    })
      // Handle the response from backend here
      .then((resp) => {
        let response = resp.data;
        let result = [];
        if (response.indexOf(",") > -1) {
          result = response.split(",");
        } else {
          result.push(response);
        }
        setList(result);
        setCheckedState(new Array(result.length).fill(false));
      })
      // Catch errors if any
      .catch((err) => {
        console.log(err);
      });
  };
  const getIPdata = async () => {
    const res = await axios.get(thankyouScreenGetIpAPIURL);
    setIpAddr(res.data.IPv4);
  };

  let bool = true;
  if (
    firstName !== "" &&
    surName !== "" &&
    email !== "" &&
    schemecode !== "" &&
    // CID !== "" &&
    // PID !== "" &&
    !schemeInvalidBool &&
    !invalidEmailBool &&
    !invalidConfirmEmailBool &&
    !noMatchEmailBool &&
    !invalidFirstNameBool &&
    !invalidSurNameBool
  ) {
    bool = false;
  }
  const errorMessageAPIpostData = { errormessages: "string" };
 
  
  useEffect(() => {
    onLoadData();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    }); 
  }, []);

  const onLoadData =async()=>{
    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey            
    }
    getErrorMessagesAPI(headers);
    getListFromAPI(headers);
    getIPdata();
  }
  useEffect(() => {
    if(list.length &&  stepOneResponseBool && errorMessages.email){     
      navigateToNextScreen();
    }    
  }, [list,errorMessages,stepOneResponseBool]); 

  const getErrorMessagesAPI = (headers) => {
    axios({
      url: errorMessageAPIURL,
      method: "POST",
      headers:headers
    })
      .then((resp) => {
        let response = resp.data;
        response =
          response.slice(0, response.lastIndexOf(",")) +
          response.slice(response.lastIndexOf(",") + 1);
        response = JSON.parse(response);
        setErrorMessages(response);
      })
      .catch((err) => {
        console.log(err);
        setErrorMessages(errorMessagesLocal);
      });
  };

  const handleClick = (e) => {
    e.preventDefault();
    bool = true;
    const postData = {
      name: firstName.trim() + " " + surName.trim(),
      employeeFirstName: firstName,
      employeeLastName: surName,
      email: email,
      schemecode: schemecode,
      cid: CIDFromURL,
      pid: PIDFromURL,
    };
    postAPItoGenerateCode(postData);
  };
  const postAPItoGenerateCode = async(postData) => {
    if (!loaderBool) setLoaderBool(true);
    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey             
    }
    axios({
      url: stepOneAPIURL,
      method: "POST",
      data: postData,
      headers:headers
    })
      .then((res) => {
        if (networkErrBool) setNetworkErrBool(false);
        setLoaderBool(false);
        let response = res.data;
        if(response.beforeStartDate){
          setSchemeBeforeBool(true); 
        }else if(response.afterEndDate){
          setSchemeAfterBool(true);
        }else if(response.Emailcode){
          setStepOneData({response:response, postData:postData});
          setStepOneResponseBool(true);
          

        }else{
          if (!schemeInvalidBool) setSchemeInvalidBool(true);
        }
        
        
      })
      .catch((err) => {
        setLoaderBool(false);          
        if (err.response) {
          if (err.response.status) {
            if (err.response.status === 400) {
              // {SchemeValid: 'No'}
              if (!schemeInvalidBool) setSchemeInvalidBool(true);
            } else if (err.response.status === 401) {
              //{Message: 'SAP ID is not generated'}
              if (!sapIdMissingBool) setSAPidMissingBool(true);
            }else{
              if (!schemeInvalidBool) setSchemeInvalidBool(true);
            }
          }
        } else if (err.code === "ERR_NETWORK") {
          if (!networkErrBool) setNetworkErrBool(true);
        } else {
          if (networkErrBool) setNetworkErrBool(false);
        }
      });
  };
 
  const navigateToNextScreen = () => {
    setStepOneResponseBool(false);
    let countryCode = stepOneData.response.CountryCode ? stepOneData.response.CountryCode : "4";
    let currencySymbol =
      countryCode === "4"
        ? stepFourOtherInfo.poundSymbol
        : stepFourOtherInfo.euroSymbol;

    let marketingDetails = {};
    list.map((item, index) => {
      item = "marketing " + item;
      marketingDetails[item] = checkedState[index];
    });
    setContext({
      ...context,
      step: 2,
      changeAmount: false,
      isPHAagreed: false,
      errorMessages: errorMessages,
      errorMessagesLocal: errorMessagesLocal,
      stepOnePostData: stepOneData.postData,
      stepOneResponse: stepOneData.response,
      ipAddr: ipAddr,
      marketingList: list,
      marketingDetails: marketingDetails,
      currencySymbol: currencySymbol,
    });
  };

  const firstNameOnChange = (e) => {
    let val = e.target.value.replace(/[^A-Za-z-'\s]/g, "");
    setFirstName(val);
  };

  const firstNameOnFocus = (e) => {
    if (
      !(
        invalidEmailBool ||
        invalidConfirmEmailBool ||
        noMatchEmailBool ||
        schemeInvalidBool
      )
    ) {
      e.target.placeholder = "";
      if (firstNameEmptyBool) setFirstNameEmptyBool(false);
      if (invalidFirstNameBool) setInvalidFirstNameBool(false);
    }
  };

  const firstNameOnBlur = (e) => {
    e.target.placeholder = stepOneOtherInfo.firstPlaceholder;
    if (!firstName.length && schemecodeFromURL === "") {
      !schemecode.length
        ? setFirstNameEmptyBool(false)
        : setFirstNameEmptyBool(true);
    } else {
      if (!/^[A-Za-z-'\s]*$/.test(firstName)) {
        if (!invalidFirstNameBool) setInvalidFirstNameBool(true);
      } else {
        if (invalidFirstNameBool) setInvalidFirstNameBool(false);
      }
    }
  };

  const surNameOnChange = (e) => {
    let val = e.target.value.replace(/[^A-Za-z-'\s]/g, "");
    setSurName(val);
  };

  const surNameOnFocus = (e) => {
    if (
      !(
        invalidEmailBool ||
        invalidConfirmEmailBool ||
        noMatchEmailBool ||
        schemeInvalidBool
      )
    ) {
      e.target.placeholder = "";
      if (surNameEmptyBool) setSurNameEmptyBool(false);
      if (invalidSurNameBool) setInvalidSurNameBool(false);
    }
  };

  const surNameOnBlur = (e) => {
    e.target.placeholder = stepOneOtherInfo.surPlaceholder;
    if (!surName.length && schemecodeFromURL === "") {
      !schemecode.length
        ? setSurNameEmptyBool(false)
        : setSurNameEmptyBool(true);
    } else {
      if (!/^[A-Za-z-'\s]*$/.test(surName)) {
        if (!invalidSurNameBool) setInvalidSurNameBool(true);
      } else {
        if (invalidSurNameBool) setInvalidSurNameBool(false);
      }
    }
  };

  const emailOnFocus = (e) => {
    if (
      !(
        invalidFirstNameBool ||
        invalidSurNameBool ||
        invalidConfirmEmailBool ||
        noMatchEmailBool ||
        schemeInvalidBool
      )
    ) {
      e.target.placeholder = "";
      if (invalidEmailBool) setInvalidEmailBool(false);
      if (emailEmptyBool) setEmailEmptyBool(false);
      if (noMatchEmailBool) setNoMatchEmailBool(false);
    }
  };

  const emailOnBlur = (e) => {
    e.target.placeholder = stepOneOtherInfo.emailPlaceholder;
    if (!email.length && schemecodeFromURL === "") {
      !schemecode.length ? setEmailEmptyBool(false) : setEmailEmptyBool(true);
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        email !== "" ? setInvalidEmailBool(true) : setInvalidEmailBool(false);
      }
    }
    if (email.length && confirmEmail.length) {
      email !== confirmEmail
        ? setNoMatchEmailBool(true)
        : setNoMatchEmailBool(false);
    }
  };

  const confirmEmailOnFocus = (e) => {
    if (
      !(
        invalidFirstNameBool ||
        invalidSurNameBool ||
        invalidEmailBool ||
        schemeInvalidBool
      )
    ) {
      e.target.placeholder = "";
      if (invalidConfirmEmailBool) setInvalidConfirmEmailBool(false);
      if (confirmEmailEmptyBool) setConfirmEmailEmptyBool(false);
      if (noMatchEmailBool) setNoMatchEmailBool(false);
    }
  };

  const confirmEmailOnBlur = (e) => {
    e.target.placeholder = stepOneOtherInfo.confirmEmailPlaceholder;
    if (!confirmEmail.length && schemecodeFromURL === "") {
      !schemecode.length
        ? setConfirmEmailEmptyBool(false)
        : setConfirmEmailEmptyBool(true);
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(confirmEmail)) {
        confirmEmail !== ""
          ? setInvalidConfirmEmailBool(true)
          : setInvalidConfirmEmailBool(false);
      } else if (confirmEmail.length && email.length) {
        email !== confirmEmail
          ? setNoMatchEmailBool(true)
          : setNoMatchEmailBool(false);
      }
    }
  };

  const schemeCodeOnChange = (e) => {
    let val = e.target.value//.replace(/[^A-Za-z0-9]/g, "");
    setSchemecode(val);
    if(schemeInvalidBool) setSchemeInvalidBool(false);
    if(schemeBeforeBool) setSchemeBeforeBool(false); 
    if(schemeAfterBool) setSchemeAfterBool(false);
  };

  const schemeCodeOnFocus = (e) => {
    e.target.placeholder = "";
    if(schemeInvalidBool) setSchemeInvalidBool(false);
    if(schemeBeforeBool) setSchemeBeforeBool(false); 
    if(schemeAfterBool) setSchemeAfterBool(false);
  };

  const schemeCodeOnBlur = (e) => {
    e.target.placeholder = stepOneOtherInfo.schemecodePlaceholder;
    if (schemecodeFromURL === "" && schemecode !== "") {
      firstName === ""
        ? setFirstNameEmptyBool(true)
        : setFirstNameEmptyBool(false);
      surName === "" ? setSurNameEmptyBool(true) : setSurNameEmptyBool(false);
      email === "" ? setEmailEmptyBool(true) : setEmailEmptyBool(false);
      confirmEmail === ""
        ? setConfirmEmailEmptyBool(true)
        : setConfirmEmailEmptyBool(false);
    }
  };

  

 

  

  return (
    <div className="App">
      <div className="loader-bool-enable">
        {loaderBool ? (
          <>
            <div className="loader buttonLoaderdiv"> </div>
            <div className="loading-text buttonLoaderdivText">
              {stepOneOtherInfo.loaderText}
            </div>
          </>
        ) : null}
      </div>
      <div className={"App " + (loaderBool === true ? "blur-background" : "")}>

        {networkErrBool && <NetworkError setNetworkPopup={setNetworkErrBool} />}
        {sapIdMissingBool && (
          <SAPidMissing
            setSAPidMissingBool={setSAPidMissingBool}
            sapIdMissingBool={sapIdMissingBool}
          />
        )}
        <div>
          <div className="form-element">
            <div style={{ display: "block" }}>
              <div className="start-text text-center">
                <ProgressBar progress={stepOneOtherInfo.progressBarCount} />
                <span>{stepOneOtherInfo.progressBarText} </span>
              </div>
              <div className="intro-label-new">
                {" "}
                {stepOneLabels.introLabel}{" "}
              </div>
              <div className="text-left d-flex">
                <label className="intro-sub-label">
                  {stepOneLabels.introSubLabel} &nbsp;&nbsp;
                  <span className="tooltip-icon">
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    <span className="tooltiptext">
                      {stepOneLabels.introSubLabelHint}
                    </span>
                  </span>
                </label>
              </div>
            </div>

            <form className="text-left">
              <div className="form-group">
                <label className="custom-text-justify">
                  {stepOneLabels.firstNameLabel}
                  <span className="asterisk-element">*</span>
                </label>
                <input
                  type="text"
                  autoComplete="no"
                  className={
                    firstNameEmptyBool
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  placeholder={stepOneOtherInfo.firstPlaceholder}
                  maxLength={stepOneOtherInfo.nameMaxLength}
                  onChange={(e) => {
                    firstNameOnChange(e);
                  }}
                  onBlur={(e) => {
                    firstNameOnBlur(e);
                  }}
                  onFocus={(e) => {
                    firstNameOnFocus(e);
                  }}
                  value={firstName}
                  required
                  autoFocus
                  readOnly={
                    loaderBool ||
                    invalidSurNameBool ||
                    invalidEmailBool ||
                    invalidConfirmEmailBool ||
                    noMatchEmailBool ||
                    schemeInvalidBool
                  }
                />{" "}
                {invalidFirstNameBool && (
                  <label className="error-message custom-text-justify">
                    {errorMessages.name
                      ? errorMessages.name
                      : errorMessagesLocal.name}
                  </label>
                )}
              </div>
              <div className="form-group">
                <label className="custom-text-justify">
                  {stepOneLabels.surNameLabel}
                  <span className="asterisk-element">*</span>
                </label>

                <input
                  type="text"
                  autoComplete="no"
                  className={
                    surNameEmptyBool
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  placeholder={stepOneOtherInfo.surPlaceholder}
                  maxLength={stepOneOtherInfo.nameMaxLength}
                  onChange={(e) => {
                    surNameOnChange(e);
                  }}
                  onBlur={(e) => {
                    surNameOnBlur(e);
                  }}
                  onFocus={(e) => {
                    surNameOnFocus(e);
                  }}
                  value={surName}
                  required
                  // autoFocus
                  readOnly={
                    loaderBool ||
                    invalidFirstNameBool ||
                    invalidEmailBool ||
                    invalidConfirmEmailBool ||
                    noMatchEmailBool ||
                    schemeInvalidBool
                  }
                />
                {invalidSurNameBool && (
                  <label className="error-message custom-text-justify">
                    {errorMessages.name
                      ? errorMessages.name
                      : errorMessagesLocal.name}
                  </label>
                )}
              </div>
              <div className="form-group">
                <label className="custom-text-justify">
                  {stepOneLabels.emailLabel}
                  <span className="asterisk-element">*</span>
                </label>
                <input
                  type="email"
                  autoComplete="no"
                  className={
                    emailEmptyBool
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  placeholder={stepOneOtherInfo.emailPlaceholder}
                  maxLength={stepOneOtherInfo.emailMaxLength}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  onFocus={(e) => {
                    emailOnFocus(e);
                  }}
                  onBlur={(e) => {
                    emailOnBlur(e);
                  }}
                  value={email}
                  required
                  readOnly={
                    loaderBool ||
                    invalidFirstNameBool ||
                    invalidSurNameBool ||
                    invalidConfirmEmailBool ||
                    schemeInvalidBool
                  }
                />
                {invalidEmailBool && (
                  <label className="error-message custom-text-justify">
                    {" "}
                    {errorMessages.email
                      ? errorMessages.email
                      : errorMessagesLocal.email}{" "}
                  </label>
                )}
              </div>
              <div className="form-group">
                <label className="custom-text-justify">
                  {stepOneLabels.confirmEmailLabel}
                  <span className="asterisk-element">*</span>
                </label>
                <input
                  type="email"
                  autoComplete="no"
                  className={
                    confirmEmailEmptyBool
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  placeholder={stepOneOtherInfo.confirmEmailPlaceholder}
                  maxLength={stepOneOtherInfo.emailMaxLength}
                  onChange={(e) => {
                    setConfirmEmail(e.target.value);
                  }}
                  onFocus={(e) => {
                    confirmEmailOnFocus(e);
                  }}
                  onBlur={(e) => {
                    confirmEmailOnBlur(e);
                  }}
                  value={confirmEmail}
                  required
                  readOnly={
                    loaderBool ||
                    invalidFirstNameBool ||
                    invalidSurNameBool ||
                    invalidEmailBool ||
                    schemeInvalidBool
                  }
                />
                {invalidConfirmEmailBool && (
                  <label className="error-message custom-text-justify">
                    {" "}
                    {errorMessages.email
                      ? errorMessages.email
                      : errorMessagesLocal.email}{" "}
                  </label>
                )}
                {noMatchEmailBool && (
                  <label className="error-message custom-text-justify">
                    {errorMessages.noMatchEmail
                      ? errorMessages.noMatchEmail
                      : errorMessagesLocal.noMatchEmail}
                  </label>
                )}
              </div>
              <div className="form-group">
                <label className="custom-text-justify">
                  {stepOneLabels.schemecodeLabel}
                  <span className="asterisk-element">*</span>
                  <span className="tooltip-icon">
                    <FontAwesomeIcon icon={faCircleExclamation} />
                    <span className="tooltiptext">
                      {stepOneLabels.schemecodeHint}
                      <br />
                      <a
                        href={stepOneLabels.schemecodeHintLinkhref}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {stepOneLabels.schemecodeHintLink}
                      </a>
                    </span>
                  </span>
                </label>
                <input
                  type="text"
                  autoComplete="no"
                  className="form-control"
                  placeholder={stepOneOtherInfo.schemecodePlaceholder}
                  maxLength={stepOneOtherInfo.schemecodeMaxLength}
                  onChange={(e) => {
                    schemeCodeOnChange(e);
                  }}
                  onBlur={(e) => {
                    schemeCodeOnBlur(e);
                  }}
                  onFocus={(e) => {
                    schemeCodeOnFocus(e);
                  }}
                  value={schemecode}
                  required
                  readOnly={
                    loaderBool ||
                    invalidFirstNameBool ||
                    invalidSurNameBool ||
                    invalidEmailBool ||
                    invalidConfirmEmailBool ||
                    noMatchEmailBool ||
                    schemecodeFromURL !== ""
                  }
                />
                {schemeInvalidBool && (
                  <label className="error-message custom-text-justify">
                    {schemecodeErrorMsg.message} <a href={schemecodeErrorMsg.link} target="_blank" rel="noreferrer">here</a> 
                    {/* get from local since we have a url and cannot be fetched from table.
                    {errorMessages.schemecode
                      ? errorMessages.schemecode
                      : errorMessagesLocal.schemecode} */}
                  </label>
                )}
                {schemeBeforeBool && (
                  <label className="error-message custom-text-justify">
                    {errorMessages.beforeStartDate
                      ? errorMessages.beforeStartDate
                      : errorMessagesLocal.beforeStartDate}
                  </label>
                )}
                {schemeAfterBool && (
                  <label className="error-message custom-text-justify">
                    {errorMessages.afterEndDate
                      ? errorMessages.afterEndDate
                      : errorMessagesLocal.afterEndDate}
                  </label>
                )}
              </div>
          
              <div className="form-group text-center">
                <button
                  disabled={bool}
                  type="submit"
                  className={
                    "btn green-btn " +
                    (bool === true
                      ? "next-disable-button-element"
                      : "next-enable-button-element")
                  }
                  onClick={(e) => handleClick(e)}
                >
                  {stepOneLabels.nextButtonText}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
