import { useState } from 'react';
import './App.css';
import { Context } from './components/Context';
import StartScreen from './components/StartScreen';
import Header from './components/Header'
import Footer from './components/Footer'

function App() {
const [context, setContext] =useState({step:0});
  return (
    <Context.Provider value={[context, setContext]}>
    <div className="App">
      <Header />
      <StartScreen />   
      <Footer />
    </div>
    </Context.Provider>
  );
}
export default App;
