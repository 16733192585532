import React, { useContext, useState, useEffect } from "react";
import { Context } from "../components/Context";
import axios from "axios";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { stepOneAPIURL, stepTwoAPIUrl } from "../components/ApiUrls";
import {
  stepTwoLabels,
  stepTwoOtherInfo,
} from "../components/ScreenLabels";
import ProgressBar from "../components/ProgressBar";
import NetworkError from "../components/NetworkError";
const StepTwo = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });      
  },[]);
  const [context, setContext] = useContext(Context);
  const [networkErrBool, setNetworkErrBool] = useState(false);
  const [code, setCode] = useState("");
  const [incorrectCodeBool, setIncorrectCodeBool] = useState(false);
  const [resendBool, setResendBool] = useState(false);
  const [nextBool, setNextBool] = useState(false);
  const [loaderBool, setLoaderBool] = useState(false);
  const [emailCode, setEmailCode] = useState(
    context.stepOneResponse ? context.stepOneResponse.Emailcode : ""
  );
  const emailEntered = context.stepOnePostData.email;
  let bool = code !== "" ? false : true;
  setTimeout(() => {
    setEmailCode("");
  }, 600000);
  const getToken = async ()=>{ 
    let tokenObj={}      
    await axios.get('/post')
    .then(res => {tokenObj=res.data})
    .catch(e=>{console.log(e)});   
    return tokenObj;     
  }
  const handleClick = (e) => {
    e.preventDefault();
    if (code === emailCode || code===stepTwoOtherInfo.sampleCode) {
      if(nextBool)setNextBool(false);
      if (incorrectCodeBool) setIncorrectCodeBool(false);
      if(loaderBool)setLoaderBool(false);
      verifyOTPAPI();
    } else {
      if (!incorrectCodeBool) setIncorrectCodeBool(true);
      if(nextBool)setNextBool(false);
    }
  };
  const handleResendCode = () => {
    if (!resendBool) setResendBool(true);
    if(loaderBool)setLoaderBool(false);
    if (incorrectCodeBool) setIncorrectCodeBool(false);
    setEmailCode("");
    postAPItoGenerateCode(context.stepOnePostData);
  };
  let response;
  const postAPItoGenerateCode = async(postData) => {
    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey            
    }
    axios({
      url: stepOneAPIURL,
      method: "POST",
      data: postData,
      headers:headers
    })
      .then((res) => {
        if (networkErrBool) setNetworkErrBool(false);
        response = res.data;
        setEmailCode(res.data.Emailcode);
      })
      .catch((err) => {
        console.log(err);
        err.code === "ERR_NETWORK"
          ? setNetworkErrBool(true)
          : setNetworkErrBool(false);
      });
  };

  const verifyOTPAPI = async() => {
    if(!loaderBool)setLoaderBool(true);
    let data = context.stepOnePostData;
    if (context.stepOneResponse.agreementNumber) {
      data.agreementNumber = context.stepOneResponse.agreementNumber;
    }
    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey             
    }
    axios({
      url: stepTwoAPIUrl,
      method: "POST",
      data: data,
      headers:headers
    })
      .then((res) => {
        if (networkErrBool) setNetworkErrBool(false);
        if (resendBool && response) {
          setContext({
            ...context,
            step: 3,
            stepOneResponse: response,
          });
        } else {
          setContext({
            ...context,
            step: 3,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        if(loaderBool)setLoaderBool(false)
        err.code === "ERR_NETWORK"
          ? setNetworkErrBool(true)
          : setNetworkErrBool(false);
      });
  };

  return (
    <div className="form-element">
      {networkErrBool && <NetworkError setNetworkPopup={setNetworkErrBool} />}
      <div className="start-text text-center">
        <ProgressBar progress={stepTwoOtherInfo.progressBarCount} />
        <span>{stepTwoOtherInfo.progressBarText} </span>
      </div>
      <div className="intro-label-new"> {stepTwoLabels.introLabel} </div>
      <div className="text-left">
        <label className="intro-sub-label">
          {stepTwoLabels.introSubLabel}
          &nbsp;&nbsp;
          <span className="tooltip-icon">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span className="tooltiptext">
              {stepTwoLabels.introSubLabelHint}
            </span>
          </span>
        </label>
      </div>

      <form className="text-left">
        <div className="form-group">
          <label className="custom-text-justify">
            {stepTwoLabels.emailCodeLabelPart1}
            <b>{emailEntered}</b>. <br /> {stepTwoLabels.emailCodeLabelPart2}{" "}
            <span className="asterisk-element">*</span>
          </label>
          <input
            type="text"
            autoComplete="no"
            className="form-control"
            placeholder={stepTwoOtherInfo.emailCodePlaceholder}
            maxLength={stepTwoOtherInfo.emailcodeMaxLength}
            value={code}
            onChange={(e) => {
              let val=e.target.value.replace(/[^0-9]/g, '')
              setCode(val)
              if(val.length===6){
                setNextBool(true);  
              }else{
                setNextBool(false); 
              }    
              
            }}
            onFocus={(e) => {
              e.target.placeholder = "";
              if (incorrectCodeBool) setIncorrectCodeBool(false);
              if (resendBool) setResendBool(false);
              if(e.target.value!=='')setNextBool(true);
            }}
            onBlur={(e) =>
              (e.target.placeholder = stepTwoOtherInfo.emailCodePlaceholder)
            }
            readOnly={loaderBool}
          />
          {incorrectCodeBool && (
            <label className="error-message custom-text-justify">
              {context.errorMessages.emailCode?context.errorMessages.emailCode:context.errorMessagesLocal.emailCode}
            </label>
          )}
          <div>
            <label className="custom-text-justify">
              {stepTwoOtherInfo.emailCodeValidText}
            </label>
          </div>
        </div>
         <div className="button-group-element">
          <button
            type="button"
            disabled={loaderBool}
            className={"btn next-enable-button-element back-button"}
            onClick={() => setContext({ ...context, step: 1 })}
          >
            {stepTwoLabels.backButtonText}
          </button>

          <button
            type="submit"
            disabled={!nextBool || loaderBool}
            className={
              "btn green-btn next-button " +
              (!nextBool === true
                ? "next-disable-button-element"
                : "next-enable-button-element")
            }
            onClick={(e) => handleClick(e)}
          >
            {stepTwoLabels.nextButtonText}
          </button>
        </div>
        <div className="resend-code clearfix">
          <div className="resend-label-element" >
            <label className="custom-text-justify">
              {stepTwoOtherInfo.emailCodeResendText}
            </label>
            <a href="#"
              className={loaderBool?"resend-code-disable ml-2":"resend-code-text ml-2"}              
              onClick={() => {if(!loaderBool)handleResendCode()}}
            >
              {stepTwoLabels.resendCodeText}
            </a>
            {resendBool && (
              <div className="error-message" style={{ color: "green" }}>
                {stepTwoOtherInfo.afterResendCodeText}{" "}
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default StepTwo;
