import React from 'react'

const ProgressBar = ({progress}) => {

    let p=progress*(100/8)
	
	const Parentdiv = {
		height: 20,
		width: '100%',
		backgroundColor: 'whitesmoke',
		borderRadius: 40,
		// margin: 50
	}
	
	const Childdiv = {
		height: '100%',
		width: `${p}%`,
		backgroundColor: '#FF9718', //'#FF9718',
	    borderRadius:40,
		textAlign: 'right'
	}
	
	const progresstext = {
		padding: 10,
		color: 'black',
		fontWeight: 900
	}
    p=Math.round(p)
	return (
	<div style={Parentdiv}>
	<div style={Childdiv}>
		<span style={progresstext}>{`${p}%`}</span>
	</div>
	</div>
	)
}

export default ProgressBar;
