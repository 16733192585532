import { useState } from "react";
import { SAPidMissingLabels, thankYouScreenLabels } from "./ScreenLabels";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const SAPidMissing = (props) => {
  const [errorPopup, setErrorPopup] = useState(props.sapIdMissingBool);
  console.log("in SAPidMissing: "+errorPopup)
    const onClickOk = () => {        
        setErrorPopup(false);       
        if(props.setSAPidMissingBool) {
          props.setSAPidMissingBool(false);
        }
        window.location.href= thankYouScreenLabels.closeButtonRedirectURL;
      }
    
      return (
        <>
          {errorPopup && <div className='card-network-popup-overlay'>
            <div className='text-center'>
              {/* <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> */}
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <div className='card-overlay-popup-text'>
                {SAPidMissingLabels.SAPidMissingText}
              </div>
              <button className='ok-button' onClick={() => onClickOk()}>{SAPidMissingLabels.okButtonText}</button>
            </div>
          </div>}
        </>
      )
};



export default SAPidMissing;