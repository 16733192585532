import React, {useContext} from 'react';
import RichTextEditor from 'react-rte';
import { Context } from './Context';
import { hireAgreementContent } from './ScreenLabels';

const HireAgreementRTE = () => {
    const [context, setContext] = useContext(Context);
    const hireAgreementContentText = hireAgreementContent.line1 +'<br><br>'+
    hireAgreementContent.line2
    +'<br><br><br>'+
    hireAgreementContent.line3Part1
    +'<strong>'+ 
    context.stepThreePostData.name
    +'</strong>'+
    hireAgreementContent.line3Part2+'<br><br>'+
    hireAgreementContent.line4Part1
    +'<strong>'+
    context.stepThreePostData.name
    +'</strong>'+
    hireAgreementContent.line4Part2 
    +'<strong>'+ 
    context.stepThreePostData.address
     +'</strong>'+
    hireAgreementContent.line4Part3 
    +'<strong>'+ 
    context.stepThreePostData.email
     +'</strong>'+
    hireAgreementContent.line4Part4+'<br><br>'+
    hireAgreementContent.line5+' <br><br><br>'+
    hireAgreementContent.line6+'<br><br><br>'+
    hireAgreementContent.line7+'<br><br><br>'+
    hireAgreementContent.line8+'<br><br><br>'+
    hireAgreementContent.line9+'<br><br><br>'+
    hireAgreementContent.line10+'<br><br>'+
    hireAgreementContent.line11+'<br><br><br>'+
    hireAgreementContent.line12+'<br><br>'+
    hireAgreementContent.line13+'<br><br>';

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
        INLINE_STYLE_BUTTONS: [
          {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
          {label: 'Italic', style: 'ITALIC'},
          {label: 'Underline', style: 'UNDERLINE'}
        ],
        BLOCK_TYPE_DROPDOWN: [
          {label: 'Normal', style: 'unstyled'},
          {label: 'Heading Large', style: 'header-one'},
          {label: 'Heading Medium', style: 'header-two'},
          {label: 'Heading Small', style: 'header-three'}
        ],
        BLOCK_TYPE_BUTTONS: [
          {label: 'UL', style: 'unordered-list-item'},
          {label: 'OL', style: 'ordered-list-item'}
        ]
      };
    
    return (
        <RichTextEditor value={RichTextEditor.createValueFromString(hireAgreementContentText, 'html')} toolbarConfig={toolbarConfig}/>
    );
};

export default HireAgreementRTE;