import React, { useContext, useEffect, useState } from "react";
import { Context } from "../components/Context";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "../components/ProgressBar";
import {
  stepFourOtherInfo,
  stepFiveLabels,
  networkErrorMsg,
} from "../components/ScreenLabels";
const StepFive = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });      
  },[]);
  const [context, setContext] = useContext(Context);
  let boldBool = false;
  let currencySymbol = context.currencySymbol;
  const limit = context.stepFourPostData.LoCVal;
  const frequency = context.stepFourPostData.Frequency;
  const respData = context.stepFourResponse;
  let term = context.stepFourPostData.term;
  let twentyPercentBracketSave,
    twentyPercentBracketPayment,
    fourtyPercentBracketSave,
    fourtyPercentBracketPayment,
    fourtyFivePercentBracketSave,
    fourtyFivePercentBracketPayment,
    percentBracketPaymentStr;

    let screenWidthMobileBool = window.innerWidth <= 567 ? true : false;
  
  const formatVal = (val) => {
    val = Math.round(val * 100) / 100 + "";
    if (val.indexOf(".") > -1) {
      let l = val.split(".")[1].length;
      if (l === 1) {
        val = val + "0";
      }
    }
    return val;
  };
  const respKeys = Object.keys(respData);
  
  if (frequency === "4 Weekly") {
    if (term.toLowerCase().indexOf("weekly") > -1) {
      term = term.split(" ")[0].trim();
    }
    percentBracketPaymentStr =
      term + " " + stepFiveLabels.paymentsOfFor4Weelky + " " + currencySymbol;
  } else {
    percentBracketPaymentStr =
      term + " " + stepFiveLabels.paymentsOf + " " + currencySymbol;
  }
  respKeys.map((item, index) => {
    if (item === "7_20Save") {
      twentyPercentBracketSave = formatVal(respData[item]);
    } else if (item === "8_20FreqPay") {
      twentyPercentBracketPayment = formatVal(respData[item]);
    } else if (item === "9_40Save") {
      fourtyPercentBracketSave = formatVal(respData[item]);
    } else if (item === "10_40FreqPay") {
      fourtyPercentBracketPayment = formatVal(respData[item]);
    } else if (item === "11_45Save") {
      fourtyFivePercentBracketSave = formatVal(respData[item]);
    } else if (item === "12_45FreqPay") {
      fourtyFivePercentBracketPayment = formatVal(respData[item]);
    }
  });

  return (
    <div>
      <div className="form-element">
        <div className="start-text text-center">
          <ProgressBar progress={stepFiveLabels.progressBarCount} />
          <span>{stepFiveLabels.progressBarText} </span>
        </div>
        <div className="intro-label-new"> {stepFiveLabels.introLabel} </div>
        <div className="text-left">
          <label className="intro-sub-label">
            {stepFiveLabels.introSubLabel}
            &nbsp;&nbsp;
            <span className="tooltip-icon">
              <FontAwesomeIcon icon={faCircleExclamation} />
              <span className="tooltiptext">
                {stepFiveLabels.introSubLabelHint}
              </span>
            </span>
          </label>
        </div>
        <form>
          <div className="form-group">
            <div className="text-left">
              <label className="custom-text-justify mb-4">
                {stepFiveLabels.subHeadingTextLabelPart1} {currencySymbol}
                {limit} {stepFiveLabels.subHeadingTextLabelPart2}
              </label>
            </div>
            <div className="text-center">
              <div className="bracket text-center">
                <div>
                  <label>{stepFiveLabels.twentyPercentTaxBracket}</label>
                  <br />
                  <div className="row-main ml-auto mr-auto">
                    <div style={{ width: "90%" }}>
                      <label className="bracket-save">
                        {stepFiveLabels.youWillSave}
                        {currencySymbol}
                        {twentyPercentBracketSave}{" "}
                      </label>
                    </div>
                    <div style={{ width: "10%" }}>
                      <span className="tooltip-icon">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        <span className="tooltiptext">
                          {stepFiveLabels.twentyPercentTaxHint}
                        </span>
                      </span>
                    </div>
                  </div>
                  <label className="bracket-payment">
                    {percentBracketPaymentStr}
                    {twentyPercentBracketPayment}
                  </label>
                  <br />
                </div>
              </div>
              <div className="bracket text-center">
                <div>
                  <label>{stepFiveLabels.fourtyPercentTaxBracket}</label>

                  <div className="row-main ml-auto mr-auto">
                    <div style={{ width: "90%" }}>
                      <label className="bracket-save">
                        {stepFiveLabels.youWillSave}
                        {currencySymbol}
                        {fourtyPercentBracketSave}{" "}
                      </label>
                    </div>

                    <div style={{ width: "10%" }}>
                      <span className="tooltip-icon">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        <span className="tooltiptext">
                          {stepFiveLabels.fourtyPercentTaxHint}
                        </span>
                      </span>
                    </div>
                  </div>
                  <label className="bracket-payment">
                    {percentBracketPaymentStr}
                    {fourtyPercentBracketPayment}
                  </label>
                  <br />
                </div>
              </div>
              <div className="bracket text-center">
                <div>
                  <label>{stepFiveLabels.fourtyFivePercentTaxBracket}</label>

                  <div className="row-main ml-auto mr-auto">
                    <div style={{ width: "90%" }}>
                      <label className="bracket-save">
                        {stepFiveLabels.youWillSave}
                        {currencySymbol}
                        {fourtyFivePercentBracketSave}{" "}
                      </label>
                    </div>
                    <div style={{ width: "10%" }}>
                      <span className="tooltip-icon">
                        <FontAwesomeIcon icon={faCircleExclamation} />
                        <span className="tooltiptext">
                          {stepFiveLabels.fourtyFivePercentTaxHint}
                        </span>
                      </span>
                    </div>
                  </div>

                  <label className="bracket-payment">
                    {percentBracketPaymentStr}
                    {fourtyFivePercentBracketPayment}
                  </label>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div           
            className={screenWidthMobileBool?"":"button-group-element" }
          >
            
            <button
              type="button"
              className="btn btn-lg changeamount-button-element"
              onClick={() =>
                setContext({ ...context, step: 4, changeAmount: true })
              }
            >
              {stepFiveLabels.backButtonText}
            </button>
            <button
              type="submit"
              className="btn btn-lg float-right green-btn sign-agreement-button-element"
              onClick={() => setContext({ ...context, step: 6 })}
            >
              {stepFiveLabels.nextButtonText}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StepFive;
