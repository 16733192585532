import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "../components/Context";
import {
  thankYouScreenLabels,  
} from "../components/ScreenLabels";
import {  
  thankyouScreenSubmitAPIurl,
} from "../components/ApiUrls";
import ProgressBar from "../components/ProgressBar";
import NetworkError from "../components/NetworkError";

const ThankyouScreen = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });      
  },[]);
  const [context, setContext] = useContext(Context);
  const [networkErrBool, setNetworkErrBool] = useState(false);
  const [list, setList] = useState(context.marketingList);

  const [checkedState, setCheckedState] = useState(
    new Array(list.length).fill(false)
  );
  // let closeRedirectUrl =
  //   document.referrer !== ""
  //     ? document.referrer
  //     : thankYouScreenLabels.closeButtonRedirectURL;

  let empEmail = context.stepThreePostData
    ? context.stepThreePostData.email
    : "";
  let screenWidthMobileBool = window.innerWidth <= 567 ? true : false;
  const getToken = async ()=>{ 
      let tokenObj={}      
      await axios.get('/post')
      .then(res => {tokenObj=res.data})
      .catch(e=>{console.log(e)});   
      return tokenObj;     
    }
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
  };
  const handleClick = (e) => {
    e.preventDefault();
    let marketingDetails = {};
    list.map((item, index) => {
      item = "marketing " + item;
      marketingDetails[item] = checkedState[index];
    });
    const postData = {
      ...marketingDetails,
      "agreementNumber":context.agreementNumber,
    };
    thankyouScreenPostAPICall(postData);
  };

  const thankyouScreenPostAPICall = async(postData) => {
    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey             
    }
    axios({
      url: thankyouScreenSubmitAPIurl,
      method: "POST",
      data: postData,
      headers:headers
    })
      .then((resp) => {
        if (networkErrBool) setNetworkErrBool(false);
        window.location.href = thankYouScreenLabels.closeButtonRedirectURL;
      })
      .catch((err) => {
        console.log(err);
        err.code === "ERR_NETWORK"
          ? setNetworkErrBool(true)
          : setNetworkErrBool(false);
      });
  };

  return (
    <div className="form-element">
      {networkErrBool && <NetworkError setNetworkPopup={setNetworkErrBool} />}
      <div className="start-text text-center">
        <ProgressBar progress={thankYouScreenLabels.progressBarCount} />
        <span>{thankYouScreenLabels.progressBarText} </span>
      </div>
      <div className="intro-label-new"> {thankYouScreenLabels.introLabel} </div>
      <div className="text-left">
        <label className="intro-sub-label">
          {thankYouScreenLabels.introSubLabel}
        </label>
      </div>
      <form className="text-left">
        <div className="form-group mb-1">
          <label>
            {thankYouScreenLabels.headingLine} {empEmail}{" "}
          </label>
        </div>
        <div className="form-group mb-1">
          <label className="">1. {thankYouScreenLabels.confirmLine1}</label>
          <br />
          <label>2. {thankYouScreenLabels.confirmLine2} </label>
          <br />
          <label>3. {thankYouScreenLabels.confirmLine3} </label>
          <br />
        </div>       

        <div className="form-group">
          <label>
            <b>{thankYouScreenLabels.marketingLabel}</b>
          </label>

          <div className="form-group">
            <label>{thankYouScreenLabels.marketingSubTextLabel}</label>
            {screenWidthMobileBool ? (
              <div className="row">
                {list.map(function (name, index) {
                  return (
                    <div className="col-sm-3 filter-button-main" key={index}>
                      <input
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name={name}
                        value={name}
                        checked={checkedState[index]}
                        onChange={() => handleOnChange(index)}
                      />{" "}
                      <label htmlFor={`custom-checkbox-${index}`}>{name}</label>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex" style={{ width: "100%" }}>
                {list.map((name, index) => {
                  return (
                    <div key={index} className="thankyou-checkbox-element">
                      <div>
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${index}`}
                          name={name}
                          value={name}
                          checked={checkedState[index]}
                          onChange={() => handleOnChange(index)}
                        />{" "}
                        <label htmlFor={`custom-checkbox-${index}`}>
                          {name}
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className="form-group text-center">
          <button
            type="submit"
            className="btn green-btn next-enable-button-element"
            onClick={(e) => handleClick(e)}
          >
            {thankYouScreenLabels.closeButtonText}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ThankyouScreen;
