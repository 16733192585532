import React, { useContext } from 'react';
import AdditionalQuestions from '../screens/AdditionalQuestions';
import StepEight from '../screens/StepEight';
import StepFive from '../screens/StepFive';
import StepFour from '../screens/StepFour';
import StepOne from '../screens/StepOne';
import StepSeven from '../screens/StepSeven';
import StepSix from '../screens/StepSix';
import StepThree from '../screens/StepThree';
import StepTwo from '../screens/StepTwo';
import ThankyouScreen from '../screens/ThankyouScreen';
import WelcomeScreen from '../screens/WelcomeScreen';
import { Context } from './Context';

const StartScreen = () => {
    const [context, setContext] = useContext(Context);

    return (
        <div>
            {context.step === 0 && <WelcomeScreen /> }
            {context.step === 1 && <StepOne /> }
            {context.step === 2 && <StepTwo /> }
            {context.step === 3 && <StepThree /> }
            {context.step === 4 && <StepFour /> }
            {context.step === 5 && <StepFive /> }
            {context.step === 6 && <StepSix /> }
            {context.step === 7 && <StepSeven /> } 
            {context.step === 8 && <StepEight /> } 
            {context.step === 9 && <AdditionalQuestions /> }
            {context.step === 10 && <ThankyouScreen /> }
        </div>
    );
};

export default StartScreen;