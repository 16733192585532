import React, { useContext, useState, useEffect } from "react";
import { Context } from "../components/Context";
import "../App.css";
import {
  faCircleExclamation,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Autocomplete from "react-autocomplete";
import {
  stepThreeLabels,
  stepThreeOtherInfo,
  networkErrorMsg,
} from "../components/ScreenLabels";
import {
  experianAddressSearchAPIURL,
  experianDetailsFromTableAPIURL,
  experianDetailsFromTablePostData,
  applicationCreateAPIurl,
} from "../components/ApiUrls";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "../components/ProgressBar";
import NetworkError from "../components/NetworkError";
const StepThree = () => {
  const [context, setContext] = useContext(Context);
  const [networkErrBool, setNetworkErrBool] = useState(false);
  const [show, setShow] = useState(false);
  const [dob, setDob] = useState(
    context.stepThreePostData ? context.stepThreePostData.dob : ""
  );
  const [address, setAddress] = useState(
    context.stepThreePostData ? context.stepThreePostData.address : ""
  );
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [addressLine3, setAddressLine3] = useState("");
  const [addressTown, setAddressTown] = useState("");
  const [addressPostCode, setAddressPostCode] = useState("");
  const [mobile, setMobile] = useState(
    context.stepThreePostData ? context.stepThreePostData.mobile : ""
  );
  const [payroll, setPayroll] = useState(
    context.stepThreePostData ? context.stepThreePostData.payroll : ""
  );

  const [requiresGuarantor, setRequiresGuarantor] = useState(false);
  const [dobReEnterBool, setDobReEnterBool] = useState(false);
  const [dobErrorBool, setDobErrorBool] = useState(false);
  const [suggArray, setSuggArray] = useState([]);
  const [addrInvalidBool, setAddrInvalidBool] = useState(false);
  const [addrBlurBool, setAddrBlurBool] = useState(false);
  const [addrManualBool, setAddrManualBool] = useState(false);
  const [mobileDigitsOnlyBool, setMobileDigitsOnlyBool] = useState(false);
  const [autofillBool, setAutofillBool] = useState(
    context.stepThreePostData ? context.stepThreePostData.autofillBool : true
  );
  const [selectedAddressBool, setSelectedAddressBool] = useState(false);
  const [dobEmptyBool, setDobEmptyBool] = useState(false);
  const [addressEmptyBool, setAddressEmptyBool] = useState(false);
  const [addressLine1EmptyBool, setAddressLine1EmptyBool] = useState(false);
  const [addressTownEmptyBool, setAddressTownEmptyBool] = useState(false);
  const [addressPostCodeEmptyBool, setAddressPostCodeEmptyBool] =
    useState(false);
  const [mobileEmptyBool, setMobileEmptyBool] = useState(false);
  const [loaderBool, setLoaderBool] = useState(false);
  const [postdataForExperianAPI, setPostdataForExperianAPI] = useState({
    country_iso: "GBR",
    datasets: "gb-address",
    max_suggestions: 100,
    "Auth-Token": "7738cb7f-af41-447f-ac3b-692e2c16c6ea",
  });
  let bool = true;
  let addFilledBool = false;
  if (addrManualBool) {
    addressLine1 !== "" && addressTown !== "" && addressPostCode !== ""
      ? (addFilledBool = true)
      : (addFilledBool = false);
  } else {
    address !== "" ? (addFilledBool = true) : (addFilledBool = false);
  }

  if (
    dob !== "" &&
    payroll !== "" &&
    mobile !== "" &&
    addFilledBool && //check if any field is empty
    !dobReEnterBool &&
    !dobErrorBool &&
    !addrInvalidBool &&
    !mobileDigitsOnlyBool // check if any field value format error
  ) {
    bool = false;
  }

  const getToken = async ()=>{ 
    let tokenObj={}      
    await axios.get('/post')
    .then(res => {tokenObj=res.data})
    .catch(e=>{console.log(e)});   
    return tokenObj;     
  }
  const getSplitAddressDetails = (addressFormatURL, val) => {
    axios({
      url: addressFormatURL,
      method: "GET",
      headers: { "Auth-Token": postdataForExperianAPI["Auth-Token"] },
    })
      .then((res) => {
        if (networkErrBool) setNetworkErrBool(false);
        if (res.data) {
          let splitAddr = res.data.result.address;
          setAddressLine1(splitAddr.address_line_1.replace(/[,]/g, ""));
          setAddressLine2(splitAddr.address_line_2.replace(/[,]/g, ""));
          setAddressLine3(splitAddr.address_line_3.replace(/[,]/g, ""));
          setAddressTown(splitAddr.locality.replace(/[,]/g, ""));
          setAddressPostCode(splitAddr.postal_code.replace(/[,]/g, ""));
        } else {
          splitAddressManually(val);
        }
      })
      .catch((err) => {
        console.log("err");
        splitAddressManually(val);
        err.code === "ERR_NETWORK"
          ? setNetworkErrBool(true)
          : setNetworkErrBool(false);
      });
  };

  const splitAddressManually = (val) => {
    if (val.indexOf(",") > -1) {
      let splitAddr = val.split(",");
      setAddressPostCode(splitAddr.pop().trim());
      setAddressTown(splitAddr.pop().trim());
      if (splitAddr.length > 3) {
        setAddressLine3(splitAddr.pop().trim());
        setAddressLine2(splitAddr.pop().trim());
        let str = "";
        let s = splitAddr.map((i) => {
          str += i;
        });
        setAddressLine1(str.trim());
      } else if (splitAddr.length < 3) {
        if (splitAddr.length === 2) {
          setAddressLine1(splitAddr[0].trim());
          setAddressLine2(splitAddr[1].trim());
          setAddressLine3("");
        } else if (splitAddr.length === 1) {
          setAddressLine1(splitAddr[0]);
          setAddressLine2("");
          setAddressLine3("");
        } else {
          setAddressLine1("");
          setAddressLine2("");
          setAddressLine3("");
        }
      } else {
        setAddressLine1(splitAddr[0].trim());
        setAddressLine2(splitAddr[1].trim());
        setAddressLine3(splitAddr[2].trim());
      }
    }
  };
 
  //postdataForExperianAPI
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    }); 
    getExperianDetailsFromTable(experianDetailsFromTablePostData);
    getDivWidth();
  }, []);

  // for exclusive iphone- width of input type date
  const getDivWidth=()=>{
    var mobileInput = document.getElementById('mobileInput');
    var dobInput = document.getElementById('dobInput');
    dobInput.style.width = mobileInput.offsetWidth+'px';
  }

  const getExperianDetailsFromTable = async(postData) => {
    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey         
    }
    axios({
      url: experianDetailsFromTableAPIURL,
      method: "POST",
      data: postData,
      headers:headers
    })
      .then((res) => {
        if (res.data) setPostdataForExperianAPI(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // search address
  useEffect(() => {
    if (address.length >= 4) {
      const postData = getPostDataForExperian(address);
      if (!addrBlurBool) {
        getAutoCompleteList(postData);
      }
    } else {
      setSuggArray([]);
    }
  }, [address, addrBlurBool]);

  const getPostDataForExperian = (address) => {
    const postData = {
      country_iso: postdataForExperianAPI.country_iso,
      datasets: [postdataForExperianAPI.datasets],
      max_suggestions: postdataForExperianAPI.max_suggestions,
      components: {
        unspecified: [address],
      },
    };
    return postData;
  };

  const getAutoCompleteList = (postData) => {
    let cancelToken;
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
      setSuggArray([]);
      console.log("request cancelled");
    }
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();
    axios({
      url: experianAddressSearchAPIURL,
      method: "POST",
      headers: { "Auth-Token": postdataForExperianAPI["Auth-Token"] },
      data: postData,
      cancelToken: cancelToken.token,
    })
      .then((res) => {
        if (networkErrBool) setNetworkErrBool(false);
        if (res.data.result.suggestions) {
          var resultArray = res.data.result.suggestions.map((item, index) => {
            return {
              label: item.text,
              id: parseInt(index + 1),
              format: item.format,
            };
          });
          setSuggArray([
            { label: stepThreeLabels.addressNotFoundOption, id: 0 },
            ...resultArray,
          ]);
        } else {
          setSuggArray([
            { label: stepThreeLabels.addressNotFoundOption, id: 0 },
          ]);
        }
      })
      .catch((err) => {
        console.log(err);
        err.code === "ERR_NETWORK"
          ? setNetworkErrBool(true)
          : setNetworkErrBool(false);
      });
  };

  const handleClick = (e) => {
    e.preventDefault();
    let addrFull = "";
    if (address === "") {
      addrFull = addressLine1 + ", ";
      addrFull += addressLine2.length ? addressLine2 + ", " : "";
      addrFull += addressLine3.length ? addressLine3 + ", " : "";
      addrFull += addressTown + ", " + addressPostCode;
    }

    const postData = {
      ...context.stepOnePostData,
      ...context.stepOneResponse,
      dob: dob,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      addressLine3: addressLine3,
      addressTown: addressTown,
      addressPostCode: addressPostCode,
      address: address.length ? address : addrFull,
      mobile: mobile,
      payroll: payroll,
      requiresGuarantor: requiresGuarantor,
      hireAgreement: false,
      autofillBool: autofillBool,
      BespokeAnswer1Other: "",
      BespokeAnswer2Other: "",
      BespokeAnswer3Other: "",
      BespokeAnswer4Other: "",
      BespokeAnswer5Other: "",
      BespokeSelectedAnswer1: "",
      BespokeSelectedAnswer2: "",
      BespokeSelectedAnswer3: "",
      BespokeSelectedAnswer4: "",
      BespokeSelectedAnswer5: "",
    };
    postAPIstepThree(postData);
  
  };

  const postAPIstepThree = async(postData) => {
    if (!loaderBool) setLoaderBool(true);
    let tokenObj= await getToken();
    let headers= {
      "Authorization": "Bearer " + tokenObj.token,
      "Ocp-Apim-Subscription-Key": tokenObj.subscriptionKey           
    }
    axios({
      url: applicationCreateAPIurl,
      method: "POST",
      data: postData,
      headers:headers
    })
      .then((response) => {
        setLoaderBool(false);
        if (networkErrBool) setNetworkErrBool(false);
        let agreementNumber=''
        if(response.data.agreementNumber){
          agreementNumber=response.data.agreementNumber
        }
        setContext({
          ...context,
          step: 4,
          stepThreePostData: postData,
          agreementNumber:agreementNumber
        });
      })
      .catch((err) => {
        setLoaderBool(false);
        console.log(err);
        err.code === "ERR_NETWORK"
          ? setNetworkErrBool(true)
          : setNetworkErrBool(false);
      });
  };

  const handleAgeModalClose = (val) => {
    setShow(false);
    val === "yes" ? setRequiresGuarantor(true) : setRequiresGuarantor(false);
    val === "no" ? setDobReEnterBool(true) : setDobReEnterBool(false);
  };

  const addressAutocompleteSelect = (val) => {
    if (val === stepThreeLabels.addressNotFoundOption) {
      setAddress("");
      if (autofillBool) setAutofillBool(false);
      if (!addrManualBool) setAddrManualBool(true);
      setAddressLine1("");
      setAddressLine2("");
      setAddressLine3("");
      setAddressTown("");
      setAddressPostCode("");
    } else {
      setAddress(val);
      if (!addrBlurBool) setAddrBlurBool(true);
      let s = suggArray.map((item) => {
        if (item.label === val) {
          getSplitAddressDetails(item.format, val);
        }
      });
    }
    setSuggArray([]);
  };

  const dobOnFocus = () => {
    if (!(addrInvalidBool || mobileDigitsOnlyBool)) {
      if (dobEmptyBool) setDobEmptyBool(false);
      if (dobErrorBool) setDobErrorBool(false);
      if (dobReEnterBool) setDobReEnterBool(false);
    }
  };

  const dobOnBlur = () => {
    if (!dob.length) {
      payroll.length ? setDobEmptyBool(true) : setDobEmptyBool(false);
    } else {
      let yr = parseInt(new Date(dob).getFullYear());
      if (yr <= 1900 || yr >= 2022) {
        if (!dobErrorBool) setDobErrorBool(true);
      } else {
        if (dobErrorBool) setDobErrorBool(false);
        let age = calculateAge();
        if(age<18){
          setShow(true);
          setRequiresGuarantor(true);
        }else{
          setShow(false);
          setRequiresGuarantor(false);
        }
      }
    }
  };
  const calculateAge = () => {
    let month_diff = Date.now() - new Date(dob).getTime();
    let age_dt = new Date(month_diff);
    let year = age_dt.getUTCFullYear();
    let age = Math.abs(year - 1970);
    return age;
  };

  const fieldEmptyValidation = () => {
    if (payroll !== "") {
      dob === "" ? setDobEmptyBool(true) : setDobEmptyBool(false);
      address === "" ? setAddressEmptyBool(true) : setAddressEmptyBool(false);
      addressLine1 === ""
        ? setAddressLine1EmptyBool(true)
        : setAddressLine1EmptyBool(false);
      addressTown === ""
        ? setAddressTownEmptyBool(true)
        : setAddressTownEmptyBool(false);
      addressPostCode === ""
        ? setAddressPostCodeEmptyBool(true)
        : setAddressPostCodeEmptyBool(false);
      mobile === "" ? setMobileEmptyBool(true) : setMobileEmptyBool(false);
    } else {
      if (dobEmptyBool) setDobEmptyBool(false);
      if (addressEmptyBool) setAddressEmptyBool(false);
      if (addressLine1EmptyBool) setAddressLine1EmptyBool(false);
      if (addressTownEmptyBool) setAddressTownEmptyBool(false);
      if (addressPostCodeEmptyBool) setAddressPostCodeEmptyBool(false);
      if (mobileEmptyBool) setMobileEmptyBool(false);
    }
  };

  const autocompleteInputOnBlur = () => {
    if (address !== "") {
      !selectedAddressBool
        ? setAddrInvalidBool(true)
        : setAddrInvalidBool(false);
    } else {
      payroll.length ? setAddressEmptyBool(true) : setAddressEmptyBool(false);
    }
    if (!addrBlurBool) setAddrBlurBool(true);
  };

  const autocompleteInputOnFocus = () => {
    if (!(dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool)) {
      if (addrBlurBool) setAddrBlurBool(false);
      if (addressEmptyBool) setAddressEmptyBool(false);
      if (selectedAddressBool) setSelectedAddressBool(false);
      if (addrInvalidBool) setAddrInvalidBool(false);
    }
  };

  const autocompleteListDivStyles = (isHighlighted) => {
    return {
      background: isHighlighted ? "#FF9718" : "white",
      margin: 5,
      width: "100%",
    };
  };

  const autocompleteInputStyles = {
    border: "1.5px solid #808080",
    borderRadius: "5px",
    height: "40px",
    width: "100%",
  };
  const autocompleteInputStylesEmpty = {
    border: "1.5px solid #DC3545",
    borderRadius: "5px",
    height: "40px",
    width: "100%",
  };

  const faCircleXmarkOnClick = () => {
    if (!autofillBool) setAutofillBool(true);
    if (addrManualBool) setAddrManualBool(false);
    setAddress("");
    setSuggArray([]);
  };

  const addressLine1OnBlur = (e) => {
    e.target.placeholder = "Address Line1";
    if (!addressLine1.length) {
      payroll.length
        ? setAddressLine1EmptyBool(true)
        : setAddressLine1EmptyBool(false);
    }
  };
  const addressLine1OnFocus = (e) => {
    if (!(dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool)) {
      e.target.placeholder = "";
      if (addressLine1EmptyBool) setAddressLine1EmptyBool(false);
    }
  };

  const addressLinesOnFocus = (e) => {
    if (!(dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool)) {
      e.target.placeholder = "";
    }
  };

  const addressTownOnFocus = (e) => {
    if (!(dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool)) {
      e.target.placeholder = "";
      if (addressTownEmptyBool) setAddressTownEmptyBool(false);
    }
  };
  const addressTownOnBlur = (e) => {
    e.target.placeholder = "Town/City";
    if (!addressTown.length) {
      payroll.length
        ? setAddressTownEmptyBool(true)
        : setAddressTownEmptyBool(false);
    } else {
      if (addressTownEmptyBool) setAddressTownEmptyBool(false);
    }
  };

  const addressPostCodeOnBlur = (e) => {
    e.target.placeholder = "Post code";
    if (!addressPostCode.length) {
      payroll.length
        ? setAddressPostCodeEmptyBool(true)
        : setAddressPostCodeEmptyBool(false);
    } else {
      if (addressPostCodeEmptyBool) setAddressPostCodeEmptyBool(false);
    }
  };
  const addressPostCodeOnFocus = (e) => {
    if (!(dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool)) {
      e.target.placeholder = "";
      if (addressPostCodeEmptyBool) setAddressPostCodeEmptyBool(false);
    }
  };

  const mobileOnBlur = (e) => {
    e.target.placeholder = stepThreeOtherInfo.mobilePlaceholder;
    if (mobile.length) {
      if(mobile.length<stepThreeOtherInfo.mobileMinLength || mobile.length >stepThreeOtherInfo.mobileMaxLength){
        setMobileDigitsOnlyBool(true)
      }else{
        setMobileDigitsOnlyBool(false);
      }
    } else {
      payroll.length ? setMobileEmptyBool(true) : setMobileEmptyBool(false);
    }
  };

  const mobileOnFocus = (e) => {
    if (!(dobReEnterBool || dobErrorBool || addrInvalidBool)) {
      e.target.placeholder = "";
      if (mobileDigitsOnlyBool) setMobileDigitsOnlyBool(false);
      if (mobileEmptyBool) setMobileEmptyBool(false);
    }
  };

  const payrollOnBlur = (e) => {
    e.target.placeholder = stepThreeOtherInfo.payrollPlaceholder;
    fieldEmptyValidation();
  };

  const payrollOnFocus = (e) => {
    if (
      !(
        dobReEnterBool ||
        dobErrorBool ||
        addrInvalidBool ||
        mobileDigitsOnlyBool
      )
    ) {
      e.target.placeholder = "";
    }
  };

  return (
    <div className="App">
    <div className="loader-bool-enable">
      {loaderBool ? (
        <>
          <div className="loader buttonLoaderdiv"> </div>
        </>
      ) : null}
    </div>
    <div className={"App " + (loaderBool === true ? "blur-background" : "")}>      
      {networkErrBool && <NetworkError setNetworkPopup={setNetworkErrBool} />}
      <div className="form-element">
        <div className="start-text text-center">
          <ProgressBar progress={stepThreeOtherInfo.progressBarCount} />
          <span>{stepThreeOtherInfo.progressBarText} </span>
        </div>
        <div className="intro-label-new"> {stepThreeLabels.introLabel} </div>
        <div className="text-left">
          <label className="intro-sub-label">
            {stepThreeLabels.introSubLabel}
            &nbsp;&nbsp;
            <span className="tooltip-icon">
              <FontAwesomeIcon icon={faCircleExclamation} />
              <span className="tooltiptext">
                {stepThreeLabels.introSubLabelHint}
              </span>
            </span>
          </label>
        </div>
        <form className="text-left">
          <div className="form-group">
            <label className="custom-text-justify">
              {stepThreeLabels.dobLabel}{" "}
              <span className="asterisk-element">*</span>
            </label>
            <br />
            <div className="dobDiv">
              <input
                type="date"
                id="dobInput"
                name="dob"
                className={
                  dobEmptyBool ? "form-control border-danger" : "form-control"
                }
                onChange={(e) => setDob(e.target.value)}
                onBlur={() => dobOnBlur()}
                onFocus={() => dobOnFocus()}
                min={stepThreeOtherInfo.dobMinValue}
                max={new Date().toJSON().slice(0, 10)}
                value={dob}
                readOnly={addrInvalidBool || mobileDigitsOnlyBool ||loaderBool}
              />
            </div>
            
            {dobErrorBool && (
              <label className="error-message custom-text-justify">
                {" "}
                {context.errorMessages.dobFormat?context.errorMessages.dobFormat:context.errorMessagesLocal.dobFormat}
              </label>
            )}
            {dobReEnterBool && (
              <label className="error-message custom-text-justify">
                {" "}
                {context.errorMessages.dobReEnter?context.errorMessages.dobReEnter:context.errorMessagesLocal.dobReEnter}
              </label>
            )}
          </div>
          {autofillBool && (
            <div className="form-group" style={{ width: "100%" }}>
              <label className="custom-text-justify">
                {stepThreeLabels.addressLabel}{" "}
                <span className="asterisk-element">*</span>
              </label>
              <div className="autocomplete-element">
                <Autocomplete
                  disabled={dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool ||loaderBool}
                  readOnly={dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool ||loaderBool}
                  items={suggArray}
                  getItemValue={(item) => item.label}
                  renderItem={(item, isHighlighted) => {
                    return (
                      <div
                        style={autocompleteListDivStyles(isHighlighted)}
                        key={item.id}
                      >
                        {item.label}
                      </div>
                    );
                  }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  onSelect={(val) => {
                    if (!selectedAddressBool) setSelectedAddressBool(true);
                    addressAutocompleteSelect(val);
                  }}
                  inputProps={{
                    style: !addressEmptyBool
                      ? autocompleteInputStyles
                      : autocompleteInputStylesEmpty,
                    placeholder: stepThreeOtherInfo.addressPlaceholder, 
                    maxLength: stepThreeOtherInfo.addressMaxLength,
                    onBlur: () => autocompleteInputOnBlur(),
                    onFocus: () => autocompleteInputOnFocus(),
                    readOnly:
                      dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool ||loaderBool,
                    disabled:dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool ||loaderBool
                  }}
                />
                <span
                  className={
                    dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool
                      ? "address-manual-disable"
                      : "address-manual-span"
                  }
                  disabled={loaderBool}
                  onClick={() => {                    
                    if (!selectedAddressBool) setSelectedAddressBool(true);
                    if (addrInvalidBool) setAddrInvalidBool(false);
                    addressAutocompleteSelect(
                      stepThreeLabels.addressNotFoundOption
                    );
                  }}
                >
                  {stepThreeLabels.manualAddressSpanText}
                </span>
              </div>
              {addrInvalidBool && (
                <>
                  <div>
                    <label className="error-message custom-text-justify">
                      {context.errorMessages.address?context.errorMessages.address:context.errorMessagesLocal.address}
                    </label>
                  </div>
                </>
              )}
            </div>
          )}
          {!autofillBool && (
            <div className="form-group">
              <div className="form-group d-flex">
                <div>
                  <em>{stepThreeLabels.manualAddrIntroLabel}</em>
                </div>
                <span
                  className="circle-xmark-element tooltip-icon"
                  onClick={() => {
                    faCircleXmarkOnClick();
                  }}
                >
                  <FontAwesomeIcon icon={faCircleXmark} />
                  <span className="tooltiptext">
                    {stepThreeLabels.manualAddrCancelToolTip}
                  </span>
                </span>
              </div>
              <div>
                <label className="custom-text-justify">
                  {stepThreeLabels.manualAddrLine1Label}
                  <span className="asterisk-element">*</span>
                </label>
                <input
                  type="text"
                  autoComplete="no"
                  className={
                    addressLine1EmptyBool
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  placeholder="Address Line1"
                  maxLength={stepThreeOtherInfo.addressMaxLength}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  onBlur={(e) => addressLine1OnBlur(e)}
                  onFocus={(e) => addressLine1OnFocus(e)}
                  value={addressLine1}
                  readOnly={
                    dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool ||loaderBool
                  }
                  required
                />
              </div>
              <div>
                <label className="custom-text-justify">
                  {stepThreeLabels.manualAddrLine2Label}
                </label>
                <input
                  type="text"
                  autoComplete="no"
                  className="form-control"
                  placeholder="Address Line2"
                  maxLength={stepThreeOtherInfo.addressMaxLength}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  onBlur={(e) => (e.target.placeholder = "Address Line2")}
                  onFocus={(e) => addressLinesOnFocus(e)}
                  value={addressLine2}
                  readOnly={
                    dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool ||loaderBool
                  }
                />
              </div>
              <div>
                <label className="custom-text-justify">
                  {stepThreeLabels.manualAddrLine3Label}
                </label>
                <input
                  type="text"
                  autoComplete="no"
                  className="form-control"
                  placeholder="Address Line3"
                  maxLength={stepThreeOtherInfo.addressMaxLength}
                  onChange={(e) => setAddressLine3(e.target.value)}
                  onBlur={(e) => (e.target.placeholder = "Address Line3")}
                  onFocus={(e) => addressLinesOnFocus(e)}
                  value={addressLine3}
                  readOnly={
                    dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool ||loaderBool
                  }
                />
              </div>
              <div>
                <label className="custom-text-justify">
                  {stepThreeLabels.manualAddrTownLabel}
                  <span className="asterisk-element">*</span>
                </label>
                <input
                  type="text"
                  autoComplete="no"
                  className={
                    addressTownEmptyBool
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  placeholder="Town/City"
                  maxLength={stepThreeOtherInfo.addressMaxLength}
                  onChange={(e) => setAddressTown(e.target.value)}
                  onBlur={(e) => addressTownOnBlur(e)}
                  onFocus={(e) => addressTownOnFocus(e)}
                  value={addressTown}
                  readOnly={
                    dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool ||loaderBool
                  }
                  required
                />
              </div>
              <div>
                <label className="custom-text-justify">
                  {stepThreeLabels.manualAddrPostCodeLabel}
                  <span className="asterisk-element">*</span>
                </label>
                <input
                  type="text"
                  autoComplete="no"
                  className={
                    addressPostCodeEmptyBool
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  placeholder="Post code"
                  maxLength={stepThreeOtherInfo.addressMaxLength}
                  onChange={(e) => setAddressPostCode(e.target.value)}
                  onBlur={(e) => addressPostCodeOnBlur(e)}
                  onFocus={(e) => addressPostCodeOnFocus(e)}
                  value={addressPostCode}
                  readOnly={
                    dobReEnterBool || dobErrorBool || mobileDigitsOnlyBool ||loaderBool
                  }
                  required
                />
              </div>
            </div>
          )}
          <div className="form-group">
            <label className="custom-text-justify">
              {stepThreeLabels.mobileLabel}{" "}
              <span className="asterisk-element">*</span>
            </label>
            <input
            id="mobileInput"
              type="text"
              autoComplete="no"
              className={
                mobileEmptyBool ? "form-control border-danger" : "form-control"
              }
              placeholder={stepThreeOtherInfo.mobilePlaceholder} 
              maxLength={stepThreeOtherInfo.mobileMaxLength} 
              onChange={(e) => setMobile(e.target.value.replace(/[^0-9]/g, ""))}
              onBlur={(e) => mobileOnBlur(e)}
              onFocus={(e) => mobileOnFocus(e)}
              value={mobile}
              readOnly={dobReEnterBool || dobErrorBool || addrInvalidBool ||loaderBool}
              required
            />
            {mobileDigitsOnlyBool && (
              <label className="error-message custom-text-justify">
                {context.errorMessages.mobile?context.errorMessages.mobile:context.errorMessagesLocal.mobile}
              </label>
            )}
          </div>
          <div className="form-group">
            <label className="custom-text-justify">
              {stepThreeLabels.payrollLabel}
              <span className="asterisk-element">*</span>
              <span className="tooltip-icon">
                <FontAwesomeIcon icon={faCircleExclamation} />
                <span className="tooltiptext">{stepThreeLabels.payrollHint}</span>
              </span>
            </label>
            <input
              type="text"
              autoComplete="no"
              className="form-control"
              placeholder={stepThreeOtherInfo.payrollPlaceholder} 
              maxLength={stepThreeOtherInfo.payrollMaxLength}
              onChange={(e) => setPayroll(e.target.value)}
              onBlur={(e) => payrollOnBlur(e)}
              value={payroll}
              onFocus={(e) => payrollOnFocus(e)}
              readOnly={
                dobReEnterBool ||
                dobErrorBool ||
                addrInvalidBool ||
                mobileDigitsOnlyBool ||
                loaderBool
              }
              required
            />
          </div>
          <div className="button-group-element">
            <button
            disabled={loaderBool}
              type="button"
              className={"btn next-enable-button-element back-button"}
              onClick={() => setContext({ ...context, step: 2 })}
            >
              {stepThreeLabels.backButtonText}
            </button>
            <button
              type="submit"
              disabled={bool || loaderBool}
              className={
                "btn green-btn next-button " +
                (bool === true
                  ? "next-disable-button-element"
                  : "next-enable-button-element")
              }
              onClick={(e) => handleClick(e)}
            >
              {stepThreeLabels.nextButtonText}
            </button>
          </div>
        </form>
        <Modal
          show={show}
          onHide={() => handleAgeModalClose("")}
          backdrop="static"
          keyboard={false}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {stepThreeLabels.dobPopupLabel}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn green-btn next-enable-button-element"
              onClick={() => handleAgeModalClose("yes")}
            >
              Yes
            </button>
            <button
              className="btn green-btn next-enable-button-element"
              onClick={() => handleAgeModalClose("no")}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
    </div>
  );
};

export default StepThree;
