import React, { useContext, useState, useEffect } from "react";
import { Context } from "../components/Context";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HireAgreementDocusign from "../components/HireAgreementDocusign";
import HireAgreementRTE from "../components/HireAgreementRTE";
import { stepSixLabels,networkErrorMsg } from "../components/ScreenLabels";
import ProgressBar from "../components/ProgressBar";
const StepSix = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });      
  },[]);
  const [context, setContext] = useContext(Context);
  let docusignBool = context.isPHAagreed?false:true;
  return (
    <div className="form-element">
      <div className="start-text text-center">
        <ProgressBar progress={stepSixLabels.progressBarCount} />
        <span>{stepSixLabels.progressBarText} </span>
      </div>
      <div className="intro-label-new"> {stepSixLabels.introLabel} </div>
      <div className="text-left">
        <label className="intro-sub-label">{stepSixLabels.introSubLabel}
          &nbsp;&nbsp;
          <span className="tooltip-icon">
            <FontAwesomeIcon icon={faCircleExclamation} />
            <span className="tooltiptext">{stepSixLabels.introSubLabelHint}</span>
          </span>
        </label>
      </div>
      <form className="text-left">
        
        {docusignBool && (
          <div className="docusign-element">
           
            {context.hireAgreementDetails ?<HireAgreementDocusign />:null }            
           
          </div>
        )}
        {!docusignBool && (
          <div>
            <div>
              <HireAgreementRTE />              
            </div>  
            <div className="button-group-element">
              <button
                type="button"
                className="btn btn-lg changeamount-button-element"
                onClick={(e) =>
                  setContext({ ...context, step: 4, changeAmount: true })
                }
              >
                {stepSixLabels.backButtonText}
              </button>
              <button
                type="submit"
                className="btn green-btn next-button next-enable-button-element"
                
                onClick={(e) => setContext({ ...context, step: 7 })}
              >
                {stepSixLabels.nextButtonText}
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default StepSix;
